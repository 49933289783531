// Vendors
import React from 'react';
// Components
import { MessageComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  TRANSACTIONS_LANDING_ROW_DETAILS_REPORT_MESSAGE_DESCRIPTION_PROPS,
  TRANSACTIONS_LANDING_ROW_DETAILS_REPORT_MESSAGE_PROPS,
} from './constants/transactions-landing-row-details-report-message.constants';
// Translations
import translations from './translations/transactions-landing-row-details-report-message.translations';
// Types
import { CardTransactionDetailReportType } from 'types/card-transtions-details-report.type';

const TransactionsLandingRowDetailsReportMessageComponent = ({
  id,
}: Pick<CardTransactionDetailReportType, 'id'> | Record<string, any>): React.ReactElement => {
  return (
    <MessageComponent {...TRANSACTIONS_LANDING_ROW_DETAILS_REPORT_MESSAGE_PROPS}>
      <ParagraphComponent {...TRANSACTIONS_LANDING_ROW_DETAILS_REPORT_MESSAGE_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={translations.REPORT_MESSAGE} values={{ reportId: id }} />
      </ParagraphComponent>
    </MessageComponent>
  );
};
export default TransactionsLandingRowDetailsReportMessageComponent;
