// Vendors
import React from 'react';
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Components
import { SidebarComponent } from '@openbank/cb-ui-commons';
import { PurchaseAmountComponent } from 'components/purchase-amount/purchase-amount.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Styles
import { TransactionsReportSidebarComponentStyled } from './transactions-report-sidebar.component.styled';
// Translations
import { TITLE } from './translations/transactions-report-sidebar.translations';
// Types
import { TransactionsReportSidebarComponentType } from './types/transactions-report-sidebar.component.type';
// Utils
import { findTransactionTotalAmount } from 'utils/transactions/transactions.utils';

export const TransactionsReportSidebarComponent = ({
  transactionDetails,
}: TransactionsReportSidebarComponentType): React.ReactElement | null => {
  if (!transactionDetails) {
    return null;
  }

  const { amounts, date, description = CharactersEnum.NO_VALUE } = transactionDetails;

  return (
    <TransactionsReportSidebarComponentStyled>
      <SidebarComponent title={<FormattedMessageComponent id={TITLE} />}>
        <PurchaseAmountComponent
          description={description}
          date={date}
          totalAmount={findTransactionTotalAmount(amounts)}
        />
      </SidebarComponent>
    </TransactionsReportSidebarComponentStyled>
  );
};
