const DOMAIN = 'cobranded.services.userSettings.privateArea.support.cbSupport.cancelContract.';

export default {
  DROPDOWN_PLACHOLDER: `${DOMAIN}dropdown.placeholder`,
  DROPDOWN_PLACHOLDER_TITLE: `${DOMAIN}firstStep.title`,
  DROPDOWN_PLACHOLDER_DESCRIPTION: `${DOMAIN}firstStep.description`,
  HEADER_TITLE: `${DOMAIN}header.title`,
  HEADER_TEXT_BOLD_1_LABEL: `${DOMAIN}header.text.bold1`,
  HEADER_TEXT_1_LABEL: `${DOMAIN}header.text1`,
  HEADER_TEXT_2_LABEL: `${DOMAIN}header.text2`,
};
