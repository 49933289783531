// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { WIZARD_STEP_FOOTER_PROPS } from 'containers/transactions/views/report/components/fraudulent-movement/constants/transactions-report-fraudulent-movement.constants';
// Hooks
import { BlockCardContextConsumerHook } from 'components/block-card/contexts/block-card.context';

const TransactionsReportFraudulentMovementBlockCardActionsComponent = (): React.ReactElement => {
  const { blockReason } = BlockCardContextConsumerHook();

  return (
    <WizardStepFooterComponent
      hasDefaultLeftButton={WIZARD_STEP_FOOTER_PROPS.hasDefaultLeftButton}
      rightButtonConfiguration={{
        disabled: !blockReason,
      }}
    />
  );
};

export default TransactionsReportFraudulentMovementBlockCardActionsComponent;
