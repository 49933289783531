// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Hooks
import { useTransactionsReportFraudulentMovementConfirmationActionsHook } from './hooks/transactions-report-fraudulent-movement-confirmation-actions.hook';
// Translations
import { WIZARD_CONFIRM_BUTTON } from 'translations/wizard.translations';

export const TransactionsReportFraudulentMovementCheckInfoActionsComponent =
  (): React.ReactElement => {
    const { challenge, challengeError, handleConfirmButtonClick } =
      useTransactionsReportFraudulentMovementConfirmationActionsHook();

    return (
      <WizardStepFooterComponent
        hasDefaultLeftButton
        rightButtonConfiguration={{
          assetConfiguration: {},
          children: <FormattedMessageComponent id={WIZARD_CONFIRM_BUTTON} />,
          disabled: !challenge || challengeError,
          onClick: handleConfirmButtonClick,
        }}
      />
    );
  };
