// Vendors
import React from 'react';
// Components
import { BlockCardAddressComponent } from 'components/block-card/components/address/block-card-address.component';
import BlockCardReasonComponent from 'components/block-card/components/block-card-reason/block-card-reason.component';
import TransactionsReportFraudulentMovementBlockCardActionsComponent from './components/actions/transactions-report-fraudulent-movement-block-card-actions.component';
// Styles
import { TransactionsReportFraudulentMovementBlockCardComponentStyled } from './transactions-report-fraudulent-movement-block-card.component.styled';

const TransactionsReportFraudulentMovementBlockCardComponent = (): React.ReactElement => (
  <TransactionsReportFraudulentMovementBlockCardComponentStyled>
    <BlockCardReasonComponent />
    <BlockCardAddressComponent reportFraudulentMovement />
    <TransactionsReportFraudulentMovementBlockCardActionsComponent />
  </TransactionsReportFraudulentMovementBlockCardComponentStyled>
);

export { TransactionsReportFraudulentMovementBlockCardComponent };
