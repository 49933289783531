// Handlers
import { ChallengeHandlers } from 'handlers/challenge/challenge.handlers';
// Contexts
import { useTransactionsReportContextConsumerHook } from 'containers/transactions/views/report/contexts/transactions-report.context';
// Types
import { ReportProblemChallengeReturnHookType } from './types/report-problem-challenge-return.hook.type';

const ReportProblemChallengeHook = (): ReportProblemChallengeReturnHookType => {
  const { challenge, challengeError, setChallenge, setChallengeError, setError } =
    useTransactionsReportContextConsumerHook();

  return {
    ...ChallengeHandlers({
      challenge,
      setChallenge,
      setChallengeError,
      setError,
    }),
    challengeError,
  };
};

export { ReportProblemChallengeHook };
