// Components
import { InputDropdownComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  CONSTANTS_REASONS_OPTIONS,
  CONSTANTS_REASON_NOT_FOUND,
} from '../constants/support-cancel-contract.constants';
// Types
import { IntlShape } from 'react-intl';

export const getReasonOptions = (
  formatMessage: IntlShape['formatMessage']
): React.ComponentProps<typeof InputDropdownComponent>['options'] =>
  Object.values(CONSTANTS_REASONS_OPTIONS).map(reason => ({
    label: formatMessage({ id: reason.label }),
    value: reason.value,
  }));
export const getReasonToCancel = (reason: string): string => {
  const foundReason = CONSTANTS_REASONS_OPTIONS.find(option => option.value === reason);
  return foundReason ? foundReason.label : CONSTANTS_REASON_NOT_FOUND;
};
