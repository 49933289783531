// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
import { TransactionsEppStep2ResumeComponent } from './components/resume/transactions-epp-step2-resume.component';
import { TransactionsEppStep2MessageComponent } from './components/message/transactions-epp-step2-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import {
  TRANSACTIONS_EPP_STEP_2_CHALLENGE_PROPS,
  TRANSACTIONS_EPP_STEP_2_FOOTER_PROPS,
} from './constants/transactions-epp-step2.constants';
// Hooks
import { useTransactionsEppStep2Hook } from './hooks/transactions-epp-step2.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import { CONFIRM } from './translations/transactions-epp-step2.translations';

const TransactionsEppStep2Component = (): React.ReactElement => {
  const {
    baseServiceBuilder,
    disabled,
    fetching,
    handleWizardChallengeErrorEvent: onError,
    handleWizardChallengeSuccessEvent: onSuccess,
    handleConfirmCreateTransactionEppPlanButtonClick: onClick,
  } = useTransactionsEppStep2Hook();

  return (
    <LayoutColumnFlexComponentStyled>
      <TransactionsEppStep2ResumeComponent />
      <TransactionsEppStep2MessageComponent />
      <ChallengeWrapperComponent
        {...TRANSACTIONS_EPP_STEP_2_CHALLENGE_PROPS}
        {...{ baseServiceBuilder, onError, onSuccess }}
      />
      <WizardStepFooterComponent
        {...TRANSACTIONS_EPP_STEP_2_FOOTER_PROPS}
        leftButtonConfiguration={{ disabled: fetching }}
        rightButtonConfiguration={{
          disabled,
          onClick,
          children: <FormattedMessageComponent id={CONFIRM} />,
          loading: fetching,
        }}
      />
    </LayoutColumnFlexComponentStyled>
  );
};

export { TransactionsEppStep2Component };
