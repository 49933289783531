// Vendors
import React from 'react';
// Components
import { TransactionsEppStep1OrchestratorComponent } from './components/orchestrator/transactions-epp-step1-orchestrator.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Hooks
import { useTransactionsEppStep1Hook } from './hooks/transactions-epp-step1.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';

const TransactionsEppStep1Component = (): React.ReactElement => {
  const { cardEnabled, disabled } = useTransactionsEppStep1Hook();

  return (
    <LayoutColumnFlexComponentStyled>
      <TransactionsEppStep1OrchestratorComponent {...{ cardEnabled }} />
      <WizardStepFooterComponent rightButtonConfiguration={{ disabled }} />
    </LayoutColumnFlexComponentStyled>
  );
};

export { TransactionsEppStep1Component };
