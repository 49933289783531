// Vendors
import React from 'react';
import { useParams } from 'react-router-dom';
// Components
import { TransactionsReportConfirmationComponent } from 'containers/transactions/views/report/components/confirmation/transactions-report-confirmation.component';
import { TransactionsReportServiceCancelledConfirmationActionsComponent } from './components/actions/transactions-report-service-cancelled-confirmation-actions.component';
import { ReportProblemChallengeComponent } from 'containers/transactions/views/report/components/challenge/report-problem-challenge.component';
// Constants
import { DISPUTE_PARAMS } from './constants/transactions-report-service-cancelled-confirmation.constants';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useTransactionsReportServiceCancelledContextConsumerHook } from '../../contexts/transactions-report-service-cancelled.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import { TransactionsReportServiceCancelledConfirmationComponentStyled } from './transactions-report-service-cancelled-confirmation.component.styled';
// Translations
import { CLAIM_TYPE } from './translations/transactions-report-service-cancelled-confirmation.translations';
// Utils
import { getDocumentIds } from 'containers/transactions/views/report/utils/transactions-report.utils';

export const TransactionsReportServiceCancelledConfirmationComponent = (): React.ReactElement => {
  const { formatMessage } = useCbIntl();
  const { cancellationDate, files } = useTransactionsReportServiceCancelledContextConsumerHook();
  const { transactionId } = useParams();
  const { currentCard } = AppContextConsumerHook(),
    { id: cardId } = currentCard || {};

  return (
    <TransactionsReportServiceCancelledConfirmationComponentStyled>
      <TransactionsReportConfirmationComponent claimType={formatMessage({ id: CLAIM_TYPE })} />
      <ReportProblemChallengeComponent
        baseServiceBuilder={{
          pathParams: {
            cardId,
            transactionId,
          },
          params: {
            ...DISPUTE_PARAMS,
            chargedForServiceAlreadyCanceled: {
              chargeCancelledDateCancelledProduct: cancellationDate,
            },
            documents: getDocumentIds(files),
          },
        }}
      />
      <TransactionsReportServiceCancelledConfirmationActionsComponent />
    </TransactionsReportServiceCancelledConfirmationComponentStyled>
  );
};
