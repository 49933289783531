// Vendors
import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
// Components
import EntrypointAuthorizationComponent from './components/authorization/entrypoint-authorization.component';
// Contexts
import { AppContextProvider } from 'contexts/app.context';
import { FetchErrorContextProvider } from 'contexts/fetch-error/fetch-error.context';
import { ManagerContextProvider } from 'contexts/manager.context';
import { QuickActionsContextProvider } from 'contexts/quick-actions/quick-actions.context';
import { ToastContextProvider } from 'contexts/toast.context';
// Providers
import { ErrorProvider } from 'providers/error/error.provider';
import { IncreaseLimitHybridProvider } from 'providers/increase-limit-hybrid/increase-limit-hybrid.provider';
import InternationalizationProvider from 'providers/internationalization/internationalization.provider';
import LocalizationProvider from 'providers/localization/localization.provider';

const EntrypointCommon = (): React.ReactElement => (
  <ErrorBoundary FallbackComponent={ErrorProvider}>
    <ManagerContextProvider>
      <AppContextProvider>
        <ToastContextProvider>
          <QuickActionsContextProvider>
            <FetchErrorContextProvider>
              <IncreaseLimitHybridProvider>
                <LocalizationProvider>
                  <InternationalizationProvider>
                    <Suspense fallback={<div />}>
                      <EntrypointAuthorizationComponent />
                    </Suspense>
                  </InternationalizationProvider>
                </LocalizationProvider>
              </IncreaseLimitHybridProvider>
            </FetchErrorContextProvider>
          </QuickActionsContextProvider>
        </ToastContextProvider>
      </AppContextProvider>
    </ManagerContextProvider>
  </ErrorBoundary>
);

export default EntrypointCommon;
