// Vendors
import {
  MiscellaneousCurrenciesEnum,
  MiscellaneousCurrencyConstants,
} from '@openbank/cf-ui-static-data';
// Constants
import { SERVICE_METHOD } from 'constants/service-method.constants';
import { REPORT_PROBLEM_SERVICE_CONFIGURATION } from 'containers/transactions/views/report/services/constants/transactions-report.service.constants';
// Translations
import {
  LABEL_TOTAL_AMOUNT_TO_CLAIM,
  LABEL_TYPE_CLAIM,
  TYPE_DEFECTIVE,
} from '../translations/report-defective-confirmation.translations';
// Types
import { DetailsType } from 'types/details.type';
import { GetReportDefectiveWizardConfigurationReturnType } from './types/report-defective-confirmation.configuration.type';
import { ParamsServiceType } from '../types/report-defective-confirmation.type';
import { UseCbIntlReturnType } from 'hooks/useCbIntl/types/use-cb-intl-return.type';

const getReportDefectiveWizardConfiguration = (
  params: ParamsServiceType
): GetReportDefectiveWizardConfigurationReturnType => {
  return {
    baseService: REPORT_PROBLEM_SERVICE_CONFIGURATION.endpoint,
    baseServiceMethod: SERVICE_METHOD.POST,
    baseServiceBuilder: {
      pathParams: { cardId: params.cardId, transactionId: params.transactionId },
      params: params.data,
    },
  };
};

const getDetailsConfiguration = (
  amount: number,
  formatNumber: UseCbIntlReturnType['formatNumber'],
  formatMessage: UseCbIntlReturnType['formatMessage']
): DetailsType => {
  const amountFormatted = formatNumber(amount, {
    ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS,
    currency: MiscellaneousCurrenciesEnum.EURO,
  });

  return [
    {
      label: LABEL_TOTAL_AMOUNT_TO_CLAIM,
      value: amountFormatted,
    },
    {
      label: LABEL_TYPE_CLAIM,
      value: formatMessage({ id: TYPE_DEFECTIVE }),
    },
  ];
};

export { getReportDefectiveWizardConfiguration, getDetailsConfiguration };
