// Translations
import { AMOUNT_RECEIVED_VALIDATION } from '../translations/transactions-report-different-amount-atm-additional-info-inputs.translations';
// Types
import { AdditionalInfoFormValuesType } from 'containers/transactions/views/report/components/different-amount-atm/components/additional-info/types/additional-info-form-values.type';

const validateIsTheAmountReceivedLessThanRequested = (
  values: AdditionalInfoFormValuesType
): boolean | string =>
  values?.movementAtmImportRequested <= values?.movementAtmImportReceived
    ? AMOUNT_RECEIVED_VALIDATION
    : true;

export { validateIsTheAmountReceivedLessThanRequested };
