// Vendors
import React from 'react';
// Components
import { InputDatePickerComponent, InputTextAreaComponent } from '@openbank/cb-ui-commons';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import {
  INPUT_DATE_PICKER_CONFIGURATION,
  INPUT_TEXT_AREA_DESCRIPTION_CONFIGURATION,
  INPUT_TEXT_AREA_PROBLEM_CONFIGURATION,
  PRODUCT_INFO_WRAPPER_TEST_ID,
} from './constants/report-defective-product-info.component.constants';
// Hooks
import { ReportDefectiveProductInfoHook } from './hooks/report-defective-product-info.hook';
// Styles
import {
  ReportProblemDefectiveInputDatePickerWrapper,
  ReportProblemDefectiveInputTextAreaWrapper,
  ReportDefectiveProductInformationContainer,
} from './report-defective-product-info.component.styled';

export const ReportDefectiveProductInfoComponent = (): React.ReactElement => {
  const {
    handleInputDatePicker,
    dateError,
    isNextButtonEnabled,
    handleNextStep,
    data: {
      defectiveProductService: {
        defectProductReturnProductCommerce,
        defectProductDescriptionProduct,
        defectProductDescriptionProblem,
      },
    },
    handleTextAreaChange,
    setProductDescription,
    setProductProblem,
    productDescription,
    productProblem,
    date,
    formatMessage,
    handleOnError,
  } = ReportDefectiveProductInfoHook();

  return (
    <ReportDefectiveProductInformationContainer {...PRODUCT_INFO_WRAPPER_TEST_ID}>
      {defectProductReturnProductCommerce && (
        <ReportProblemDefectiveInputDatePickerWrapper>
          <InputDatePickerComponent
            {...INPUT_DATE_PICKER_CONFIGURATION}
            onSelectDate={(value: string) => handleInputDatePicker(value)}
            customErrorMessage={dateError}
            errorMessage={dateError}
            value={date}
            onError={handleOnError}
          />
        </ReportProblemDefectiveInputDatePickerWrapper>
      )}
      <ReportProblemDefectiveInputTextAreaWrapper>
        <InputTextAreaComponent
          {...INPUT_TEXT_AREA_DESCRIPTION_CONFIGURATION}
          onChange={(value: string) =>
            handleTextAreaChange(
              value,
              setProductDescription,
              INPUT_TEXT_AREA_DESCRIPTION_CONFIGURATION.id
            )
          }
          value={productDescription || defectProductDescriptionProduct}
          placeholder={formatMessage({ id: INPUT_TEXT_AREA_DESCRIPTION_CONFIGURATION.placeholder })}
        />
      </ReportProblemDefectiveInputTextAreaWrapper>
      <ReportProblemDefectiveInputTextAreaWrapper>
        <InputTextAreaComponent
          {...INPUT_TEXT_AREA_PROBLEM_CONFIGURATION}
          onChange={(value: string) =>
            handleTextAreaChange(value, setProductProblem, INPUT_TEXT_AREA_PROBLEM_CONFIGURATION.id)
          }
          value={productProblem || defectProductDescriptionProblem}
          placeholder={formatMessage({ id: INPUT_TEXT_AREA_PROBLEM_CONFIGURATION.placeholder })}
        />
      </ReportProblemDefectiveInputTextAreaWrapper>
      <WizardStepFooterComponent
        hasDefaultLeftButton
        rightButtonConfiguration={{
          disabled: !isNextButtonEnabled,
          onClick: () => handleNextStep(),
        }}
      />
    </ReportDefectiveProductInformationContainer>
  );
};
