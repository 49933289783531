// Constants
import { messageConstants } from '@openbank/cb-ui-commons';

export const TRANSACTIONS_LANDING_ROW_DETAILS_REPORT_MESSAGE_PROPS = {
  type: messageConstants.TYPES.INFORMATIVE,
  testId: 'transactions-landing-row-details-report-message',
};

export const TRANSACTIONS_LANDING_ROW_DETAILS_REPORT_MESSAGE_DESCRIPTION_PROPS = {
  testId: 'transactions-landing-row-details-report-message-description',
  bold: true,
};
