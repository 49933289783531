// Vendors
import React from 'react';
// Components
import { UploadDocumentsTipsComponent } from 'components/upload-doc-tips/upload-documents-tips.component';
import { ReportSubscriptionAlreadyCancelledDocumentsActionsComponent } from './components/actions/report-subscription-already-cancelled-documents-actions.component';
import { UploadDocumentGroupComponent } from 'components/upload-document-group/upload-document-group.component';
// Contexts
import { useReportSubscriptionAlreadyCancelledContextConsumerHook } from '../../contexts/report-subscription-already-cancelled.context';
// Styles
import { ReportSubscriptionAlreadyCancelledDocumentsComponentStyled } from './report-subscription-already-cancelled-documents.component.styled';

export const ReportSubscriptionAlreadyCancelledDocumentsComponent = (): React.ReactElement => {
  const { files, setFiles } = useReportSubscriptionAlreadyCancelledContextConsumerHook();

  return (
    <ReportSubscriptionAlreadyCancelledDocumentsComponentStyled>
      <UploadDocumentsTipsComponent />
      <UploadDocumentGroupComponent files={files} onChangeFiles={setFiles} />
      <ReportSubscriptionAlreadyCancelledDocumentsActionsComponent files={files} />
    </ReportSubscriptionAlreadyCancelledDocumentsComponentStyled>
  );
};
