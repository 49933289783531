export default {
  CONTEXT_DEFAULT_VALUE: {
    cardContractId: [],
    cards: [],
    currentCard: {},
    customerId: null,
    featureFlags: [],
    languagePreference: null,
    localization: {},
    openRequestSignatureModal: false,
    profile: {},
    setCardContractId: (): null => null,
    setCards: (): null => null,
    setCurrentCard: (): null => null,
    setCustomerId: (): null => null,
    setFeatureFlags: (): null => null,
    setLanguagePreference: (): null => null,
    setLocalization: (): null => null,
    setOpenRequestSignatureModal: (): null => null,
    setProfile: (): null => null,
  },
};
