// Vendors
import React from 'react';
import { useForm } from 'react-hook-form';
// Components
import { MessageComponent } from '@openbank/cb-ui-commons';
import { TransactionsReportPaymentAdditionalInfoActionsComponent } from './components/actions/transactions-report-payment-additional-info-actions.component';
import { TransactionsReportPaymentAdditionalInfoInputComponent } from './components/input/transactions-report-payment-additional-info-input.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  MESSAGE_COMPONENT_PROPS,
  USE_FORM_OPTIONS,
} from './constants/transactions-report-payment-additional-info.constants';
// Contexts
import { useTransactionsReportPaymentContextConsumerHook } from '../../contexts/transactions-report-payment.context';
// Styles
import { TransactionsReportPaymentAdditionalInfoComponentStyled } from './transactions-report-payment-additional-info.component.styled';
// Types
import { AdditionalInfoFormValuesType } from './types/additional-info-form-values.type';

export const TransactionsReportPaymentAdditionalInfoComponent = (): React.ReactElement => {
  const { paymentMethod } = useTransactionsReportPaymentContextConsumerHook();
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<AdditionalInfoFormValuesType>({
    ...USE_FORM_OPTIONS,
    values: { paymentMethod },
  });

  return (
    <TransactionsReportPaymentAdditionalInfoComponentStyled>
      <TransactionsReportPaymentAdditionalInfoInputComponent {...{ control }} />
      <TransactionsReportPaymentAdditionalInfoActionsComponent {...{ handleSubmit, isValid }} />
    </TransactionsReportPaymentAdditionalInfoComponentStyled>
  );
};
