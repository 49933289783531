// Vendors
import React from 'react';
// Components
import { UploadDocumentsTipsComponent } from 'components/upload-doc-tips/upload-documents-tips.component';
import { TransactionsReportNotReceivedDocumentsActionsComponent } from './components/actions/transactions-report-not-received-documents-actions.component';
import { UploadDocumentGroupComponent } from 'components/upload-document-group/upload-document-group.component';
// Contexts
import { useTransactionsReportNotReceivedContextConsumerHook } from '../../contexts/transactions-report-not-received.context';
// Styles
import { TransactionsReportNotReceivedDocumentsComponentStyled } from './transactions-report-not-received-documents.component.styled';

export const TransactionsReportNotReceivedDocumentsComponent = (): React.ReactElement => {
  const { files, setFiles } = useTransactionsReportNotReceivedContextConsumerHook();

  return (
    <TransactionsReportNotReceivedDocumentsComponentStyled>
      <UploadDocumentsTipsComponent />
      <UploadDocumentGroupComponent files={files} onChangeFiles={setFiles} />
      <TransactionsReportNotReceivedDocumentsActionsComponent files={files} />
    </TransactionsReportNotReceivedDocumentsComponentStyled>
  );
};
