const DOMAIN_REPORT_A_PROBLEM_DIFFERENT_AMOUNT_ATM_ADDITIONAL_INFO_INPUTS =
  'cobranded.services.userSettings.privateArea.transactions.reportAProblem.differentAmountAtm.additionalInfo.inputs.';

const AMOUNT_REQUESTED = `${DOMAIN_REPORT_A_PROBLEM_DIFFERENT_AMOUNT_ATM_ADDITIONAL_INFO_INPUTS}amountRequested.label`;

const AMOUNT_RECEIVED = `${DOMAIN_REPORT_A_PROBLEM_DIFFERENT_AMOUNT_ATM_ADDITIONAL_INFO_INPUTS}amountReceived.label`;

const MORE_INFO = `${DOMAIN_REPORT_A_PROBLEM_DIFFERENT_AMOUNT_ATM_ADDITIONAL_INFO_INPUTS}moreInfo.label`;

const AMOUNT_RECEIVED_VALIDATION = `${DOMAIN_REPORT_A_PROBLEM_DIFFERENT_AMOUNT_ATM_ADDITIONAL_INFO_INPUTS}amountReceived.validationMessage`;

const MORE_INFO_PLACEHOLDER = `${DOMAIN_REPORT_A_PROBLEM_DIFFERENT_AMOUNT_ATM_ADDITIONAL_INFO_INPUTS}moreInfo.placeholder`;

export {
  AMOUNT_RECEIVED_VALIDATION,
  AMOUNT_RECEIVED,
  AMOUNT_REQUESTED,
  MORE_INFO_PLACEHOLDER,
  MORE_INFO,
};
