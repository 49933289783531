// Vendors
import React from 'react';
// Components
import { TransactionsReportFraudulentMovementUploadDocumentsActionsComponent } from './components/actions/transactions-report-fraudulent-movement-upload-documents-actions.component';
import { UploadDocumentGroupComponent } from 'components/upload-document-group/upload-document-group.component';
import { UploadDocumentsTipsComponent } from 'components/upload-doc-tips/upload-documents-tips.component';
// Contexts
import { TransactionsReportFraudulentMovementConsumerHook } from '../../contexts/transactions-report-fraudulent-movement.context';
// Styles
import { TransactionsReportFraudulentMovementUploadDocumentsComponentStyled } from './transactions-report-fraudulent-movement-upload-documents.component.styled';

export const TransactionsReportFraudulentMovementUploadDocumentsComponent =
  (): React.ReactElement => {
    const { files, setFiles } = TransactionsReportFraudulentMovementConsumerHook();

    return (
      <TransactionsReportFraudulentMovementUploadDocumentsComponentStyled>
        <UploadDocumentsTipsComponent />
        <UploadDocumentGroupComponent files={files} onChangeFiles={setFiles} />
        <TransactionsReportFraudulentMovementUploadDocumentsActionsComponent />
      </TransactionsReportFraudulentMovementUploadDocumentsComponentStyled>
    );
  };
