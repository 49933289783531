// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import AmortizationModalComponent from 'containers/financing/views/landing/components/row/components/details/components/amortizationTable/components/amortizationModal/amortization-modal.component';
import { ModalComponent } from '@openbank/cb-ui-commons';
// Constants
import { TRANSACTIONS_EPP_MODAL_PROPS } from './constants/transactions-epp-modal.constants';
// Hooks
import { useTransactionsEppModalHook } from './hooks/transactions-epp-modal.hook';
// Translations
import { AMORTIZATION_TABLE } from './translations/transactions-epp-modal.translations';

const TransactionsEppModalComponent = (): React.ReactElement | null => {
  const { handleToggleAmortizationTableModalLinkClick, installments, showAmortizationTableModal } =
    useTransactionsEppModalHook();

  return showAmortizationTableModal ? (
    <ModalComponent
      {...TRANSACTIONS_EPP_MODAL_PROPS}
      onHeaderClose={handleToggleAmortizationTableModalLinkClick}
      title={<FormattedMessageComponent id={AMORTIZATION_TABLE} />}
    >
      <AmortizationModalComponent {...{ installments }} />
    </ModalComponent>
  ) : null;
};

export { TransactionsEppModalComponent };
