// Vendors
import React from 'react';
import { useForm } from 'react-hook-form';
// Components
import { TransactionsReportServiceCancelledAdditionalInfoActionsComponent } from './components/actions/transactions-report-service-cancelled-additional-info-actions.component';
import { TransactionsReportServiceCancelledAdditionalInfoInputsComponent } from './components/inputs/transactions-report-service-cancelled-additional-info-inputs.component';
// Constants
import { USE_FORM_OPTIONS } from './constants/transactions-report-service-cancelled-additional-info.constants';
// Contexts
import { useTransactionsReportServiceCancelledContextConsumerHook } from '../../contexts/transactions-report-service-cancelled.context';
// Styles
import { TransactionsReportServiceCancelledAdditionalInfoComponentStyled } from './transactions-report-service-cancelled-additional-info.component.styled';
// Types
import { AdditionalInfoFormValuesType } from './types/additional-info-form-values.type';

export const TransactionsReportServiceCancelledAdditionalInfoComponent = (): React.ReactElement => {
  const { cancellationDate } = useTransactionsReportServiceCancelledContextConsumerHook();
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<AdditionalInfoFormValuesType>({
    ...USE_FORM_OPTIONS,
    values: { date: cancellationDate },
  });

  return (
    <TransactionsReportServiceCancelledAdditionalInfoComponentStyled>
      <TransactionsReportServiceCancelledAdditionalInfoInputsComponent {...{ control, errors }} />
      <TransactionsReportServiceCancelledAdditionalInfoActionsComponent
        {...{ handleSubmit, isValid }}
      />
    </TransactionsReportServiceCancelledAdditionalInfoComponentStyled>
  );
};
