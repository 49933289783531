// Vendors
import React from 'react';
// Components
import { ResumeItemComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  TRANSACTIONS_LANDING_ROW_DETAILS_DATE_PROPS,
  TRANSACTION_DATE_FORMAT_OPTIONS,
} from './constants/transactions-landing-row-details-date.constants';
// Translations
import translations from './translations/transactions-landing-row-details-date.translations';
// Types
import { TransactionsLandingRowDetailsDateComponentType } from './types/transactions-landing-row.details-date.component.utils.type';
// Utils
import { getDetailsDateTime } from './utils/transactions-landing-row-details-date.component.util';

const TransactionsLandingRowDetailsDateComponent = ({
  date,
  formatDate,
  time,
}: TransactionsLandingRowDetailsDateComponentType): React.ReactElement => (
  <ResumeItemComponent
    {...TRANSACTIONS_LANDING_ROW_DETAILS_DATE_PROPS}
    label={<FormattedMessageComponent id={translations.DATE} />}
    value={
      <FormattedMessageComponent
        id={translations.TIME}
        values={{
          dateTime: getDetailsDateTime({
            date,
            time,
            formatDate,
            options: TRANSACTION_DATE_FORMAT_OPTIONS,
          }),
        }}
      />
    }
  />
);

export default TransactionsLandingRowDetailsDateComponent;
