// Resolvers
import {
  notificationsDetailsResolver,
  notificationsUpdateResolver,
} from 'resolvers/notifications.resolvers';
// Types
import { NotificationsBuilderReturnHandlersType } from './types/notifications-builder-return.handlers.type';
import { NotificationsBuilderPropsHandlersType } from './types/notifications-builder-props.handlers.type';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Utils
import { generatePathRoute } from 'utils/app-routing.utils';

const getAllCustomerNotificationsHandler = async ({
  setNotificationsData,
  params,
}: Pick<
  NotificationsBuilderPropsHandlersType,
  'setNotificationsData' | 'params'
>): Promise<any> => {
  const [response, error] = await notificationsDetailsResolver({ params });

  setNotificationsData?.(!error ? response : []);
};

const notificationsLinkClicked = ({
  navigate,
  headerRef,
  handleMakeUserClickOnAllNotifications,
}) => {
  navigate(RoutesEnumeration.NOTIFICATIONS);

  handleMakeUserClickOnAllNotifications();

  headerRef.current.click();
};

const goToNotificationsSettingsHandler = ({ navigate, headerRef }) => {
  navigate(
    generatePathRoute([RoutesEnumeration.NOTIFICATIONS, RoutesEnumeration.NOTIFICATIONS_SETTINGS])
  );

  headerRef.current.click();
};

const NotificationsHandlers = ({
  handleMakeUserClickOnAllNotifications,
  headerRef,
  navigate,
  params,
  setNotificationsData,
}: NotificationsBuilderPropsHandlersType): NotificationsBuilderReturnHandlersType => ({
  handleGetAllCustomerNotifications: () =>
    getAllCustomerNotificationsHandler({ setNotificationsData, params }),
  handleGoToNotificationsSettings: () => goToNotificationsSettingsHandler({ navigate, headerRef }),
  handleMakeUserNotificationsClickHandler: () =>
    notificationsLinkClicked({ navigate, headerRef, handleMakeUserClickOnAllNotifications }),
  handleOpenNotificationModal: () => notificationsUpdateResolver(),
});

export { NotificationsHandlers };
