// Types
import { TransactionsEppStep1HookUtilsType } from './types/transactions-epp-step1.hook.utils.type';
// Utilities
import { simulationPlanIsNotDone } from 'containers/transactions/views/epp/components/step1/utils/transactions-epp-step1.utils';
import { cardActive } from 'utils/card.utils';

const eppPlanInstallmentStep1FooterIsDisabled = ({
  eppPlanSimulation,
  status,
}: TransactionsEppStep1HookUtilsType): boolean =>
  Boolean(!cardActive(status) || simulationPlanIsNotDone(eppPlanSimulation));

export { eppPlanInstallmentStep1FooterIsDisabled };
