// Vendors
import React from 'react';
import { useForm } from 'react-hook-form';
// Components
import { MessageComponent } from '@openbank/cb-ui-commons';
import { TransactionsReportNotReceivedAdditionalInfoActionsComponent } from './components/actions/transactions-report-not-received-additional-info-actions.component';
import { TransactionsReportNotReceivedAdditionalInfoInputsComponent } from './components/inputs/transactions-report-not-received-additional-info-inputs.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  MESSAGE_COMPONENT_PROPS,
  USE_FORM_OPTIONS,
} from './constants/transactions-report-not-received-additional-info.constants';
// Contexts
import { useTransactionsReportNotReceivedContextConsumerHook } from '../../contexts/transactions-report-not-received.context';
// Styles
import { TransactionsReportNotReceivedAdditionalInfoComponentStyled } from './transactions-report-not-received-additional-info.component.styled';
// Translations
import { MESSAGE_LABEL } from './translations/transactions-report-not-received-additional-info.component.translations';
// Types
import { AdditionalInfoFormValuesType } from './types/additional-info-form-values.type';

export const TransactionsReportNotReceivedAdditionalInfoComponent = (): React.ReactElement => {
  const { notReceivedDate, description } = useTransactionsReportNotReceivedContextConsumerHook();
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<AdditionalInfoFormValuesType>({
    ...USE_FORM_OPTIONS,
    values: { date: notReceivedDate, description },
  });

  return (
    <TransactionsReportNotReceivedAdditionalInfoComponentStyled>
      <MessageComponent {...MESSAGE_COMPONENT_PROPS}>
        <FormattedMessageComponent isNormalFont id={MESSAGE_LABEL} />
      </MessageComponent>
      <TransactionsReportNotReceivedAdditionalInfoInputsComponent {...{ control, errors }} />
      <TransactionsReportNotReceivedAdditionalInfoActionsComponent {...{ handleSubmit, isValid }} />
    </TransactionsReportNotReceivedAdditionalInfoComponentStyled>
  );
};
