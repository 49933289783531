// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { DEFAULT_DISABLED_VALUE } from './constants/transactions-report-fraudulent-movement-upload-documents-actions.constants';

export const TransactionsReportFraudulentMovementUploadDocumentsActionsComponent =
  (): React.ReactElement => (
    <WizardStepFooterComponent
      hasDefaultLeftButton
      rightButtonConfiguration={DEFAULT_DISABLED_VALUE}
    />
  );
