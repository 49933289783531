// Configurations
import { getErrorAccordingStatus } from 'organisms/table-organism/components/transactions-table/handlers/configurations/transactions-table.handlers.configuration';
// Types
import { TransactionsTableBuilderHandlersType } from 'organisms/table-organism/components/transactions-table/handlers/types/transactions-table-builder.handlers.type';
import { TransactionsTableDetailBuilderHandlersType } from './types/transactions-landing-row-detail-builder.handlers.type';
import { TransactionsTableDetailBuilderReturnHandlersType } from './types/transactions-landing-row-detail-return-builder.handlers.type';
// Utilities
import { isLegitTransactionsDetailResponse } from './utils/transactions-landing-row-detail.handlers.utils';

const fetchCardsTransactionsDetailHandler = async ({
  cardId,
  transactionId,
  resolver = () => Promise.resolve([]),
  setFetching,
  setError,
  setDetails,
}: Omit<TransactionsTableDetailBuilderHandlersType, 'wrapperError'>): Promise<void> => {
  setFetching(true);

  const [response, error] = await resolver({ cardId, transactionId });

  if (error) {
    setError(getErrorAccordingStatus(error));
  } else if (isLegitTransactionsDetailResponse(response)) {
    setDetails(response);
  }
  setFetching(false);
};

const setOutProvidingErrorEvent = ({
  setError,
  wrapperError,
}: Pick<TransactionsTableBuilderHandlersType, 'setError' | 'wrapperError'>): any => {
  setError(wrapperError);
};

const TransactionsTableDetailHandlers = ({
  cardId,
  transactionId,
  resolver,
  setFetching,
  setError,
  setDetails,
  wrapperError,
}: TransactionsTableDetailBuilderHandlersType): TransactionsTableDetailBuilderReturnHandlersType => ({
  handleFetchCardTransactionsDetail: () =>
    fetchCardsTransactionsDetailHandler({
      cardId,
      transactionId,
      resolver,
      setFetching,
      setError,
      setDetails,
    }),
  handleSetOutProvidingErrorEvent: () => setOutProvidingErrorEvent({ setError, wrapperError }),
});

export default TransactionsTableDetailHandlers;
