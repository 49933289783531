// Vendors
import React from 'react';
// Components
import { DetailConfirmationComponent } from 'components/detail-confirmation/detail-confirmation.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ReportProblemChallengeComponent } from 'containers/transactions/views/report/components/challenge/report-problem-challenge.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Configurations
import {
  getDetailsConfiguration,
  getReportDefectiveWizardConfiguration,
} from './configurations/report-defective-confirmation.configuration';
// Hooks
import { ReportProblemDefectiveConfirmationHook } from './hooks/report-defective-confirmation.hook';
// Translations
import { WIZARD_CONFIRM_BUTTON } from 'translations/wizard.translations';

export const ReportDefectiveConfirmationComponent = (): React.ReactElement => {
  const {
    handleReportProblem,
    params,
    amount,
    formatNumber,
    formatMessage,
    challenge,
    challengeError,
  } = ReportProblemDefectiveConfirmationHook();

  return (
    <>
      <DetailConfirmationComponent
        details={getDetailsConfiguration(amount, formatNumber, formatMessage)}
      />
      <ReportProblemChallengeComponent {...getReportDefectiveWizardConfiguration(params)} />
      <WizardStepFooterComponent
        hasDefaultLeftButton
        hasDefaultRightButton
        rightButtonConfiguration={{
          assetConfiguration: {},
          children: <FormattedMessageComponent id={WIZARD_CONFIRM_BUTTON} />,
          disabled: !challenge || challengeError,
          onClick: handleReportProblem,
        }}
      />
    </>
  );
};
