// Types
import { TransactionLandingTableApiBuilderComponentType } from '../types/transactions-landing-service.type';

const FETCH_TRANSACTIONS_SERVICE_CONFIGURATION: TransactionLandingTableApiBuilderComponentType = {
  endpoint: 'cb-api-cards/v3/cards/:cardId/transactions',
  queryParams: {
    limit: 25,
  },
};

export { FETCH_TRANSACTIONS_SERVICE_CONFIGURATION };
