// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Types
import { TransactionsReportServiceCancelledDocumentsActionsComponentType } from './types/transactions-report-service-cancelled-documents-actions.component.type';
// Utils
import { hasRequiredDocs } from 'containers/transactions/views/report/utils/transactions-report.utils';
import { DocumentsTypesEnumeration } from 'enumerations/documents/documents-types.enumeration';

export const TransactionsReportServiceCancelledDocumentsActionsComponent = ({
  files,
}: TransactionsReportServiceCancelledDocumentsActionsComponentType): React.ReactElement => (
  <WizardStepFooterComponent
    hasDefaultLeftButton
    rightButtonConfiguration={{
      disabled: !hasRequiredDocs({
        files,
        requiredDocType: DocumentsTypesEnumeration.PROOF_OF_CONTACT,
      }),
    }}
  />
);
