const DOMAIN_CANCEL_CONTRACT =
  'cobranded.services.userSettings.privateArea.support.cbSupport.cancelContract.';
const PAGE_TITLE = `${DOMAIN_CANCEL_CONTRACT}pageTitle`;
const SUCCESS = {
  CONFIRMATION: {
    TITLE: `${DOMAIN_CANCEL_CONTRACT}successTitle`,
    CONTENT: `${DOMAIN_CANCEL_CONTRACT}successContent`,
  },
  BACK_BUTTTON: `${DOMAIN_CANCEL_CONTRACT}successBack`,
};

const STEP_1 = `${DOMAIN_CANCEL_CONTRACT}Step1Title`;
const STEP_2 = `${DOMAIN_CANCEL_CONTRACT}Step2Title`;

const FAILED = {
  CONFIRMATION: {
    TITLE: `${DOMAIN_CANCEL_CONTRACT}failedTitle`,
    CONTENT: `${DOMAIN_CANCEL_CONTRACT}failedContent`,
  },
  CANCEL_BUTTTON: `${DOMAIN_CANCEL_CONTRACT}failedCancel`,
  TRY_AGAIN_BUTTTON: `${DOMAIN_CANCEL_CONTRACT}failedTryAgain`,
};

export { SUCCESS, FAILED, PAGE_TITLE, STEP_1, STEP_2 };
