// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { NUMBER } from 'constants/number.constants';
import { EMPTY_STRING } from '../constants/report-defective-product-info.component.constants';
// Translations
import {
  ERROR_DATE,
  ERROR_DATE_GREATER_THAN_TODAY,
  INVALID_DATE,
} from '../translations/report-defective-product-info.component.constants';
import {
  ReportDefectiveProductInfoHandlerPropsType,
  ReportDefectiveProductInfoHandlerReturnType,
} from './types/report-defective-product-info.handler.type';
// Type
import { SetStateType } from '@openbank/cf-ui-static-data';
import { OnErrorHandlerPropsType } from './types/on-error-handler-props.type';

const handleDate = ({
  setDate,
  setDateError,
  setData,
  setIsNextButtonEnabled,
  transactionDetails,
  value,
}): void => {
  if (value.length && value < transactionDetails?.date) {
    setDateError(<FormattedMessageComponent id={ERROR_DATE} />);
    setIsNextButtonEnabled(false);
    return;
  }
  if (value.length && value > new Date().toISOString().split('T')[0]) {
    setDateError(<FormattedMessageComponent id={ERROR_DATE_GREATER_THAN_TODAY} />);
    setIsNextButtonEnabled(false);
    return;
  }

  setDateError(EMPTY_STRING);
  setDate(value);
  setData(prevState => ({
    ...prevState,
    defectiveProductService: {
      ...prevState.defectiveProductService,
      defectProductDateReturn: value,
    },
  }));
};

const handleTextArea = (value, setter, id, setData): void => {
  setter(value);
  setData(prevState => ({
    ...prevState,
    defectiveProductService: {
      ...prevState.defectiveProductService,
      [id]: value,
    },
  }));
};

const handleNextButton = ({ setCurrentStep }): void => {
  setCurrentStep(NUMBER.THREE);
};

const handleEnabledButton = ({
  setIsNextButtonEnabled,
  data,
  productDescription,
  productProblem,
  dateError,
  date,
}): void => {
  const { defectProductReturnProductCommerce } = data.defectiveProductService;
  const hasDate = !defectProductReturnProductCommerce || date;
  const shouldEnableNextButton = Boolean(
    !dateError && hasDate && productProblem && productDescription
  );

  setIsNextButtonEnabled(shouldEnableNextButton);
};

const onErrorHandler = ({
  setDateError,
  setIsNextButtonEnabled,
  formatMessage,
  hasInvalidDate,
}: OnErrorHandlerPropsType) => {
  if (hasInvalidDate) {
    setDateError(formatMessage({ id: INVALID_DATE }));
    setIsNextButtonEnabled(false);
  }
};

const ReportDefectiveProductInfoHandler = (
  props: ReportDefectiveProductInfoHandlerPropsType
): ReportDefectiveProductInfoHandlerReturnType => ({
  handleInputDatePicker: (value: string | Date) => handleDate({ ...props, value }),
  handleTextAreaChange: (value: string, setter: SetStateType<string>, id: string) =>
    handleTextArea(value, setter, id, props.setData),
  handleNextStep: () => handleNextButton({ ...props }),
  handleNextButtonEnabled: () => handleEnabledButton({ ...props }),
  handleOnError: (hasInvalidDate: boolean) =>
    onErrorHandler({
      setDateError: props.setDateError,
      setIsNextButtonEnabled: props.setIsNextButtonEnabled,
      formatMessage: props.formatMessage,
      hasInvalidDate,
    }),
});

export { ReportDefectiveProductInfoHandler };
