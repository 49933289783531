// Components
import SupportCancelContractFirstStep from '../components/first-step/support-cancel-contract-first-step.component';
import SupportCancelContractSecondStep from '../components/second-step/support-cancel-contract-second-step.component';
// Translations
import {
  FAILED,
  SUCCESS,
  STEP_1,
  STEP_2,
} from '../translations/support-cancel-contract-step.configuration.translations';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

const CANCEL_CONTRACT_WIZARD_PROPS = {
  feedbackConfiguration: {
    errorConfiguration: {
      description: FAILED.CONFIRMATION.CONTENT,
      title: FAILED.CONFIRMATION.TITLE,
      exitRoute: RoutesEnumeration.SUPPORT,
    },
    successConfiguration: {
      description: SUCCESS.CONFIRMATION.CONTENT,
      title: SUCCESS.CONFIRMATION.TITLE,
      exitRoute: RoutesEnumeration.SUPPORT,
      linkLabel: SUCCESS.BACK_BUTTTON,
    },
  },
  steps: [
    {
      component: SupportCancelContractFirstStep,
      title: STEP_1,
    },
    {
      component: SupportCancelContractSecondStep,
      title: STEP_2,
    },
  ],
};

export { CANCEL_CONTRACT_WIZARD_PROPS };
