// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useReportDefectiveContextConsumerHook } from 'containers/transactions/views/report/components/defective/contexts/report-defective.context';
import { useTransactionsReportContextConsumerHook } from 'containers/transactions/views/report/contexts/transactions-report.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Handlers
import { ReportProblemDefectiveConfirmationHandlers } from '../handlers/report-problem-defective-confirmation.handlers';
// Types
import { ReportProblemDefectiveHookPropsType } from './types/report-problem-defective.hook.type';
// Utils
import { findTransactionTotalAmount } from 'utils/transactions/transactions.utils';
import useCbIntl from 'hooks/useCbIntl';

const ReportProblemDefectiveConfirmationHook = (): ReportProblemDefectiveHookPropsType => {
  const { formatNumber, formatMessage } = useCbIntl();
  const { currentCard } = AppContextConsumerHook(),
    { id: cardId } = currentCard;

  const { challenge, challengeError, setChallengeError, transactionDetails, setCaseId } =
    useTransactionsReportContextConsumerHook();

  const { data, setData, setFiles } = useReportDefectiveContextConsumerHook(),
    { setError } = useTransactionsReportContextConsumerHook(),
    { setResult } = useWizardFeedbackContextConsumerHook();

  const { id: transactionId, amounts } = transactionDetails || {};

  const params = { cardId, transactionId, data };

  const amount = findTransactionTotalAmount(amounts || []);

  return {
    ...ReportProblemDefectiveConfirmationHandlers({
      challenge,
      setCaseId,
      setChallengeError,
      setResult,
      setError,
      setData,
      setFiles,
      cardId,
      transactionId,
      data,
    }),
    params,
    amount,
    formatNumber,
    formatMessage,
    challenge,
    challengeError,
  };
};

export { ReportProblemDefectiveConfirmationHook };
