// Vendors
import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
// Components
import { TransactionsRouterEppGuardComponent } from './components/epp-guard/transactions-router-epp-guard.component';
import { TransactionsRouterReportGuardComponent } from './components/report-guard/transactions-router-report-guard.component';
// Constants
import {
  TRANSACTIONS_LANDING_ROUTE_PROPS,
  TRANSACTIONS_PLAN_ROUTE_PROPS,
  TRANSACTIONS_REPORT_ROUTE_PROPS,
} from './constants/transactions-router.constants';
// Views
import { TransactionsReportView } from '../../views/report/transactions-report.view';
import { TransactionsEppComponent } from 'containers/transactions/views/epp/transactions-epp.component';

const TransactionsLandingView = lazy(() => import('../../views/landing/transactions-landing.view'));

const TransactionsRouterComponent = (): React.ReactElement => (
  <Routes>
    <Route {...TRANSACTIONS_LANDING_ROUTE_PROPS} element={<TransactionsLandingView />} />
    <Route element={<TransactionsRouterEppGuardComponent />}>
      <Route {...TRANSACTIONS_PLAN_ROUTE_PROPS} element={<TransactionsEppComponent />} />
    </Route>
    <Route element={<TransactionsRouterReportGuardComponent />}>
      <Route {...TRANSACTIONS_REPORT_ROUTE_PROPS} element={<TransactionsReportView />} />
    </Route>
  </Routes>
);

export { TransactionsRouterComponent };
