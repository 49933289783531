// Constants
import { NUMBER } from 'constants/number.constants';
// Utils
import { getDocumentIds } from 'containers/transactions/views/report/utils/transactions-report.utils';
// Types
import { ReportDefectiveUploadDocumentsHookReturnType } from '../hooks/types/report-defective-upload-documents.hook.type';
import { ReportDefectiveUploadDocumentsHandlerPropsType } from './types/report-defective-upload-documents.handler.type';

const filesHandler = ({ files, setData, setCurrentStep, data }) => {
  const documentIds = getDocumentIds(files) || [];
  setData({ ...data, documents: documentIds });
  setCurrentStep(NUMBER.FOUR);
};

const ReportDefectiveUploadDocumentsHandler = (
  props: ReportDefectiveUploadDocumentsHandlerPropsType
): Pick<ReportDefectiveUploadDocumentsHookReturnType, 'handleFiles'> => ({
  handleFiles: () => filesHandler(props),
});

export { ReportDefectiveUploadDocumentsHandler };
