// Types
import { SelectedOptionsType } from 'types/selected-options.type';

const hasSelectedOptions = ({
  notRecognizeChargeClickLink,
  notRecognizeChargeHaveYourCard,
  notRecognizeChargeMotiveFraud,
}: Pick<
  SelectedOptionsType,
  'notRecognizeChargeClickLink' | 'notRecognizeChargeHaveYourCard' | 'notRecognizeChargeMotiveFraud'
>): boolean =>
  notRecognizeChargeClickLink !== undefined &&
  notRecognizeChargeHaveYourCard !== undefined &&
  !!notRecognizeChargeMotiveFraud;

export { hasSelectedOptions };
