// Vendors
import React from 'react';
// Components
import AppPrivateComponent from '../private/app-private.component';
import AppProspectComponent from '../prospect/app-prospect.component';
import AppNoUserDataComponent from '../no-user-data/app-no-user-data.component';
// Hooks
import { EntrypointAuthorizationHook } from './hooks/entrypoint-authorization.hook';
// Utils
import { isUserLogged } from 'utils/session/session.utils';

const EntrypointAuthorizationComponent = (): React.ReactElement => {
  const { noNeedsUserData } = EntrypointAuthorizationHook();

  if (isUserLogged() && noNeedsUserData) {
    return <AppNoUserDataComponent />;
  } else if (isUserLogged()) {
    return <AppPrivateComponent />;
  }
  return <AppProspectComponent />;
};

export default EntrypointAuthorizationComponent;
