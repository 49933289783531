// Vendors
import React from 'react';
import { useForm } from 'react-hook-form';
// Components
import { TransactionsReportDifferentAmountAtmAdditionalInfoInputsComponent } from './components/inputs/transactions-report-different-amount-atm-additional-info-inputs.component';
import { TransactionsReportDifferentAmountAtmAdditionalInfoActionsComponent } from './components/actions/transactions-report-different-amount-atm-additional-info-actions.component';
// Constants
import { USE_FORM_OPTIONS } from './constants/transactions-report-different-amount-atm-additional-info.constants';
// Hooks
import { useTransactionsReportDifferentAmountAtmContextConsumerHook } from '../../context/transactions-report-different-amount-atm.context';
// Styles
import { TransactionsReportDifferentAmountAtmAdditionalInfoComponentStyled } from './transactions-report-different-amount-atm-additional-info.styled';
// Types
import { AdditionalInfoFormValuesType } from './types/additional-info-form-values.type';

export const TransactionsReportDifferentAmountAtmAdditionalInfoComponent =
  (): React.ReactElement => {
    const { formValues } = useTransactionsReportDifferentAmountAtmContextConsumerHook(),
      {
        control,
        formState: { errors, isValid },
        handleSubmit,
      } = useForm<AdditionalInfoFormValuesType>({
        ...USE_FORM_OPTIONS,
        values: formValues,
      });

    return (
      <TransactionsReportDifferentAmountAtmAdditionalInfoComponentStyled>
        <TransactionsReportDifferentAmountAtmAdditionalInfoInputsComponent
          {...{ control, errors }}
        />

        <TransactionsReportDifferentAmountAtmAdditionalInfoActionsComponent
          {...{ handleSubmit, isValid }}
        />
      </TransactionsReportDifferentAmountAtmAdditionalInfoComponentStyled>
    );
  };
