// Types
import { CardTransactionDetailReportType } from 'types/card-transtions-details-report.type';
import { CardTransactionsDetailType } from 'types/card-transtions-details.type';

const showDetailComponent = (details: CardTransactionsDetailType): boolean => {
  return !!Object.keys(details || {}).length;
};

const showMessage = (report: CardTransactionDetailReportType): boolean => {
  if (!report?.active && report?.id?.trim().length) return true;
  return false;
};

export { showDetailComponent, showMessage };
