// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Types
import { ReportSubscriptionAlreadyCancelledDocumentsActionsComponentType } from './types/report-subscription-already-cancelled-documents-actions.component.type';
// Utils
import { hasRequiredDocs } from './utils/report-subscription-already-cancelled-documents-actions.utils';

export const ReportSubscriptionAlreadyCancelledDocumentsActionsComponent = ({
  files,
}: ReportSubscriptionAlreadyCancelledDocumentsActionsComponentType): React.ReactElement => (
  <WizardStepFooterComponent
    hasDefaultLeftButton
    rightButtonConfiguration={{ disabled: !hasRequiredDocs(files) }}
  />
);
