// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { WIZARD_CONFIRM_BUTTON } from 'translations/wizard.translations';
// Hooks
import { useTransactionsReportDifferentAmountAtmConfirmationActionsHook } from './hooks/transactions-report-different-amount-atm-confirmation-actions.hook';

export const TransactionsReportDifferentAmountAtmConfirmationActionsComponent =
  (): React.ReactElement => {
    const { challenge, challengeError, handleConfirmButtonClick } =
      useTransactionsReportDifferentAmountAtmConfirmationActionsHook();

    return (
      <WizardStepFooterComponent
        hasDefaultLeftButton
        rightButtonConfiguration={{
          assetConfiguration: {},
          children: <FormattedMessageComponent id={WIZARD_CONFIRM_BUTTON} />,
          disabled: !challenge || challengeError,
          onClick: handleConfirmButtonClick,
        }}
      />
    );
  };
