// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Hooks
import { useTransactionsReportDifferentAmountAtmAdditionalInfoActionsHook } from './hooks/transactions-report-different-amount-atm-additional-info-actions.hook';
// Types
import { TransactionsReportDifferentAmountAtmAdditionalInfoActionsComponentType } from './types/transactions-report-different-amount-atm-additional-info-actions.component.type';

export const TransactionsReportDifferentAmountAtmAdditionalInfoActionsComponent = ({
  handleSubmit,
  isValid,
}: TransactionsReportDifferentAmountAtmAdditionalInfoActionsComponentType): React.ReactElement => {
  const { handleNextButtonClick } =
    useTransactionsReportDifferentAmountAtmAdditionalInfoActionsHook();

  return (
    <WizardStepFooterComponent
      rightButtonConfiguration={{
        disabled: !isValid,
        onClick: handleSubmit(handleNextButtonClick),
      }}
    />
  );
};
