// Vendors
import React from 'react';
import { useParams } from 'react-router-dom';
// Components
import { TitleHeaderComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import { TransactionsReportConfirmationComponent } from 'containers/transactions/views/report/components/confirmation/transactions-report-confirmation.component';
import { TransactionsReportDuplicateChargeConfirmationActionsComponent } from './components/actions/transactions-report-duplicate-charge-confirmation-actions.component';
import { ReportProblemChallengeComponent } from 'containers/transactions/views/report/components/challenge/report-problem-challenge.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  DESCRIPTION_PARAMS,
  DISPUTE_PARAMS,
  TITLE_PARAMS,
} from './constants/transactions-report-duplicate-charge-confirmation.constants';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import { TransactionsReportDuplicateChargeConfirmationComponentStyled } from './transactions-report-duplicate-charge-confirmation.component.styled';
// Translations
import { CLAIM_TYPE } from './translations/transactions-report-duplicate-charge-confirmation.translations';
import {
  CONFIRMATION_DESCRIPTION,
  CONFIRMATION_TITLE,
} from 'containers/transactions/views/report/translations/transactions-report.translations';

export const TransactionsReportDuplicateChargeConfirmationComponent = (): React.ReactElement => {
  const { formatMessage } = useCbIntl();
  const { transactionId } = useParams();
  const { currentCard } = AppContextConsumerHook(),
    { id: cardId } = currentCard || {};

  return (
    <TransactionsReportDuplicateChargeConfirmationComponentStyled>
      <TitleHeaderComponent {...TITLE_PARAMS}>
        <FormattedMessageComponent id={CONFIRMATION_TITLE} />
      </TitleHeaderComponent>
      <ParagraphComponent {...DESCRIPTION_PARAMS}>
        <FormattedMessageComponent id={CONFIRMATION_DESCRIPTION} />
      </ParagraphComponent>
      <TransactionsReportConfirmationComponent claimType={formatMessage({ id: CLAIM_TYPE })} />
      <ReportProblemChallengeComponent
        baseServiceBuilder={{
          pathParams: {
            cardId,
            transactionId,
          },
          params: {
            ...DISPUTE_PARAMS,
          },
        }}
      />
      <TransactionsReportDuplicateChargeConfirmationActionsComponent />
    </TransactionsReportDuplicateChargeConfirmationComponentStyled>
  );
};
