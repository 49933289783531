// Vendors
import React from 'react';
// Components
import { TitleHeaderComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
import { TransactionsReportLandingMessageComponent } from './components/message/transactions-report-landing-message.component';
import { TransactionsReportLandingOptionsComponent } from './components/options/transactions-report-landing-options.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  DESCRIPTION_PARAGRAPH_PROPS,
  TITLE_PROPS,
} from './constants/transactions-report-landing.constants';
// Hooks
import { useTransactionsReportLandingHook } from './hooks/transactions-report-landing.hook';
// Styles
import { TransactionsReportLandingComponentStyled } from './transactions-report-landing.component.styled';
// Translations
import { TITLE, DESCRIPTION } from './translations/transactions-report-landing.translations';

export const TransactionsReportLandingComponent = (): React.ReactElement => {
  const { selected, handleNextButtonClick, handleOptionClick } = useTransactionsReportLandingHook();

  return (
    <TransactionsReportLandingComponentStyled>
      <TitleHeaderComponent {...TITLE_PROPS}>
        <FormattedMessageComponent id={TITLE} />
      </TitleHeaderComponent>
      <ParagraphComponent {...DESCRIPTION_PARAGRAPH_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION} />
      </ParagraphComponent>
      <TransactionsReportLandingOptionsComponent selected={selected} onChange={handleOptionClick} />
      <TransactionsReportLandingMessageComponent />
      <WizardStepFooterComponent
        rightButtonConfiguration={{ disabled: !selected, onClick: handleNextButtonClick }}
      />
    </TransactionsReportLandingComponentStyled>
  );
};
