// Constexts
import { useReportDefectiveContextConsumerHook } from 'containers/transactions/views/report/components/defective/contexts/report-defective.context';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Handlers
import { ReportDefectiveUploadDocumentsHandler } from '../handlers/report-defective-upload-documents.handler';
// Types
import { ReportDefectiveUploadDocumentsHookReturnType } from './types/report-defective-upload-documents.hook.type';

const ReportDefectiveUploadDocumentsHook = (): ReportDefectiveUploadDocumentsHookReturnType => {
  const { files, setFiles, setData, data } = useReportDefectiveContextConsumerHook();
  const { setCurrentStep } = useWizardContextConsumerHook();

  return {
    files,
    setFiles,
    ...ReportDefectiveUploadDocumentsHandler({ files, setData, setCurrentStep, data }),
  };
};

export { ReportDefectiveUploadDocumentsHook };
