// Types
import { ChallengeErrorHandlerType } from './types/challenge-error-handler.type';
import { ChallengeRetryButtonClickHandlerType } from './types/challenge-retry-button-click-handler.type';
import { ChallengeSuccessHandlerType } from './types/challenge-success-handler.type';
import { ChallengeHandlersType } from './types/challenge.handlers.type';
import { ChallengeReturnHandlersType } from './types/challenge-return.handlers.type';

const challengeErrorHandler = ({ challenge, setError }: ChallengeErrorHandlerType) => {
  if (!challenge) setError(true);
};

const challengeSuccessHandler = ({ challenge, setChallenge }: ChallengeSuccessHandlerType) => {
  setChallenge(challenge);
};

const challengeRetryButtonClickHandler = ({
  setChallenge,
  setChallengeError,
}: ChallengeRetryButtonClickHandlerType) => {
  setChallenge(undefined);
  setChallengeError(false);
};

const ChallengeHandlers = ({
  challenge,
  setChallenge,
  setChallengeError,
  setError,
}: ChallengeHandlersType): ChallengeReturnHandlersType => ({
  handleChallengeError: () => challengeErrorHandler({ challenge, setError }),
  handleChallengeSuccess: challenge => challengeSuccessHandler({ challenge, setChallenge }),
  handleChallengeRetryButtonClick: () =>
    challengeRetryButtonClickHandler({ setChallenge, setChallengeError }),
});

export { ChallengeHandlers };
