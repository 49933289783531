// Components
import { TransactionsEppSidebarComponent } from 'containers/transactions/views/epp/components/sidebar/transactions-epp-sidebar.component';
import { TransactionsEppStep2Component } from 'containers/transactions/views/epp/components/step2/transactions-epp-step2.component';
import { TransactionsEppStep1Component } from 'containers/transactions/views/epp/components/step1/transactions-epp-step1.component';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import { TITLE as STEP_1_TITLE } from '../components/step1/translations/transactions-epp-step1.translations';
import { TITLE as STEP_2_TITLE } from '../components/step2/translations/transactions-epp-step2.translations';
import {
  ERROR_DESCRIPTION,
  ERROR_TITLE,
  EXIT_LINK,
  EXIT,
  SUCCESS_TITLE,
} from '../translations/transactions-epp-feedback.translations';
// Utilities
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const TRANSACTIONS_EPP_WIZARD_PROPS = {
    feedbackConfiguration: {
      errorConfiguration: {
        description: ERROR_DESCRIPTION,
        title: ERROR_TITLE,
        linkLabelId: EXIT_LINK,
        exitRoute: RoutesEnumeration.TRANSACTIONS,
      },
      successConfiguration: {
        linkLabel: EXIT,
        title: SUCCESS_TITLE,
        exitRoute: RoutesEnumeration.TRANSACTIONS,
      },
    },
    steps: [
      {
        component: TransactionsEppStep1Component,
        title: STEP_1_TITLE,
      },
      {
        component: TransactionsEppStep2Component,
        title: STEP_2_TITLE,
      },
    ],
  },
  TRANSACTIONS_EPP_LAYOUT_PROPS = {
    sidebarComponent: TransactionsEppSidebarComponent,
  },
  FINANCING_PPI_CONTRACTING_COMMON_CANCEL_PROPS = {
    cancelModal: {
      cancelUrl: generateAbsoluteRoute(
        generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.PPI])
      ),
    },
  };

export {
  TRANSACTIONS_EPP_WIZARD_PROPS,
  TRANSACTIONS_EPP_LAYOUT_PROPS,
  FINANCING_PPI_CONTRACTING_COMMON_CANCEL_PROPS,
};
