// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Hooks
import { TransactionsReportFraudulentMovementConsumerHook } from 'containers/transactions/views/report/components/fraudulent-movement/contexts/transactions-report-fraudulent-movement.context';
// Utils
import { hasSelectedOptions } from './utils/transactions-report-fraudulent-movement-additional-info-actions.utils';

const TransactionsReportFraudulentMovementAdditionalInfoActionsComponent =
  (): React.ReactElement => {
    const {
      selectedOptions: {
        notRecognizeChargeHaveYourCard,
        notRecognizeChargeClickLink,
        notRecognizeChargeMotiveFraud,
      },
    } = TransactionsReportFraudulentMovementConsumerHook();

    return (
      <WizardStepFooterComponent
        rightButtonConfiguration={{
          disabled: !hasSelectedOptions({
            notRecognizeChargeHaveYourCard,
            notRecognizeChargeClickLink,
            notRecognizeChargeMotiveFraud,
          }),
        }}
      />
    );
  };

export { TransactionsReportFraudulentMovementAdditionalInfoActionsComponent };
