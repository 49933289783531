// Vendors
import { useEffect, useState } from 'react';
// Context
import { AppContextConsumerHook } from 'contexts/app.context';
// Enumerations
import { TransactionsFetchErrorEnumeration } from 'enumerations/transactions-fetch-error.enumeration';
// Event handlers
import TransactionsTableDetailHandlers from '../handlers/transactions-landing-row-detail.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { CardTransactionsDetailType } from 'types/card-transtions-details.type';
import { TransactionsLandingRowDetailsHookType } from './types/transactions-landing-row-details.hook.type';
import { TransactionsLandingRowDetailsHookReturnType } from './types/transactions-landing-row-details-return.hook.type';

const TransactionsLandingRowDetailsHook = ({
  id,
  open,
  resolverDetail,
  error: wrapperError = null,
}: TransactionsLandingRowDetailsHookType): TransactionsLandingRowDetailsHookReturnType => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<TransactionsFetchErrorEnumeration | null>(wrapperError);
  const [details, setDetails] = useState<CardTransactionsDetailType | any>({});

  const { formatDate } = useCbIntl();

  const { currentCard } = AppContextConsumerHook(),
    { id: cardId } = currentCard;

  const { handleFetchCardTransactionsDetail } = TransactionsTableDetailHandlers({
    cardId: cardId,
    transactionId: id,
    resolver: resolverDetail,
    setFetching,
    setError,
    setDetails,
    wrapperError,
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (open) handleFetchCardTransactionsDetail();
  }, [open]);

  return {
    fetching,
    formatDate,
    error,
    details,
  };
};

export default TransactionsLandingRowDetailsHook;
