// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Types
import { TransactionsReportPaymentDocumentsActionsComponentType } from './types/transactions-report-payment-documents-actions.component.type';
// Utils
import { hasRequiredDocs } from './utils/transactions-report-payment-documents-actions.utils';

export const TransactionsReportPaymentDocumentsActionsComponent = ({
  files,
}: TransactionsReportPaymentDocumentsActionsComponentType): React.ReactElement => (
  <WizardStepFooterComponent
    hasDefaultLeftButton
    rightButtonConfiguration={{ disabled: !hasRequiredDocs(files) }}
  />
);
