// Vendors
import React from 'react';
// Components
import CardStatusComponent from 'components/card-status/card-status.component';
import { TransactionsEppStep1SimulationComponent } from '../simulation/transactions-epp-step1-simulation.component';
// Types
import { TransactionsEppStep1HookType } from '../../hooks/types/transactions-epp-step1.hook.type';

const TransactionsEppStep1OrchestratorComponent = ({
  cardEnabled,
}: Pick<TransactionsEppStep1HookType, 'cardEnabled'>): React.ReactElement =>
  cardEnabled ? <TransactionsEppStep1SimulationComponent /> : <CardStatusComponent />;

export { TransactionsEppStep1OrchestratorComponent };
