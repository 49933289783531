// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import {
  TransactionsLandingRowDetailsCreatePlanModalBuilderHandlersType,
  TransactionsLandingRowDetailsCreatePlanModalMethodsHandlersType,
} from './types/transactions-landing-row-details-create-plan-modal.handlers.type';
// Utilities
import { generatePathRoute } from 'utils/app-routing.utils';

const confirmContinueCreatePlanButtonClickHandler = ({
  handleConfirmContinueCreatePlanButtonClickTracking,
  id,
  navigate,
}: TransactionsLandingRowDetailsCreatePlanModalBuilderHandlersType): void => {
  handleConfirmContinueCreatePlanButtonClickTracking();
  navigate(generatePathRoute([id, RoutesEnumeration.CREATE_INSTALLMENTS_PLAN]));
};

const TransactionsLandingRowDetailsCreatePlanModalHandlers = (
  props: TransactionsLandingRowDetailsCreatePlanModalBuilderHandlersType
): TransactionsLandingRowDetailsCreatePlanModalMethodsHandlersType => ({
  handleConfirmContinueCreatePlanButtonClick: () =>
    confirmContinueCreatePlanButtonClickHandler(props),
});

export { TransactionsLandingRowDetailsCreatePlanModalHandlers };
