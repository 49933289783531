// Vendors
import React from 'react';
// Components
import { DetailConfirmationComponent } from 'components/detail-confirmation/detail-confirmation.component';
// Constants
import { EURO_FORMAT_OPTIONS } from 'constants/currency.constants';
// Contexts
import { useTransactionsReportContextConsumerHook } from '../../contexts/transactions-report.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Translations
import {
  CLAIM_TYPE_LABEL,
  TOTAL_AMOUNT_LABEL,
} from './translations/transactions-report-confirmation.translations';
// Types
import { TransactionsReportConfirmationComponentType } from './types/transactions-report-confirmation.component.type';
// Utils
import { findTransactionTotalAmount } from 'utils/transactions/transactions.utils';

export const TransactionsReportConfirmationComponent = ({
  additional = [],
  claimType,
}: TransactionsReportConfirmationComponentType): React.ReactElement | null => {
  const { formatMessage, formatNumber } = useCbIntl();
  const { transactionDetails } = useTransactionsReportContextConsumerHook();

  if (!transactionDetails) {
    return null;
  }

  const { amounts } = transactionDetails;

  return (
    <DetailConfirmationComponent
      details={[
        {
          label: TOTAL_AMOUNT_LABEL,
          value: formatNumber(findTransactionTotalAmount(amounts), EURO_FORMAT_OPTIONS),
        },
        {
          label: CLAIM_TYPE_LABEL,
          value: formatMessage({ id: claimType }),
        },
        ...additional,
      ]}
    />
  );
};
