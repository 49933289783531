// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Hooks
import { useTransactionsReportServiceCancelledConfirmationActionsHook } from './hooks/transactions-report-service-cancelled-confirmation-actions.hook';
// Translations
import { CONFIRM_BUTTON_LABEL } from 'containers/transactions/views/report/translations/transactions-report.translations';

export const TransactionsReportServiceCancelledConfirmationActionsComponent =
  (): React.ReactElement => {
    const { challenge, challengeError, handleConfirmButtonClick } =
      useTransactionsReportServiceCancelledConfirmationActionsHook();

    return (
      <WizardStepFooterComponent
        hasDefaultLeftButton
        rightButtonConfiguration={{
          assetConfiguration: {},
          children: <FormattedMessageComponent id={CONFIRM_BUTTON_LABEL} />,
          disabled: !challenge || challengeError,
          onClick: handleConfirmButtonClick,
        }}
      />
    );
  };
