// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { FETCH_TRANSACTIONS_SERVICE_CONFIGURATION } from './constants/transactions-landing.service.constants';
// Types
import { TransactionsApiReturnComponentType } from 'types/transactions-api-return.component.type';
import { TransactionsLandingServiceType } from './types/transactions-landing-service.type';
// Utils
import { setParams } from 'organisms/table-organism/components/transactions-table/components/security-check-modal/utils/security-check-modal.service.utils';

const fetchCardsTransactionsService = async ({
  cardId,
  formValuesParams,
  metadata,
  challenge,
}: TransactionsLandingServiceType): Promise<
  [TransactionsApiReturnComponentType | Record<string, any>, Response]
> => {
  const SERVICE_CONFIGURATION = { ...FETCH_TRANSACTIONS_SERVICE_CONFIGURATION };
  const { progressId } = challenge || {};

  SERVICE_CONFIGURATION.queryParams = {
    ...SERVICE_CONFIGURATION.queryParams,
    ...formValuesParams,
  };

  setParams({ metadata, serviceConfiguration: SERVICE_CONFIGURATION });

  return apiUtilsCb.get({
    ...SERVICE_CONFIGURATION,
    pathParams: { cardId },
    customHeaders: progressId ? { progressId } : {},
  });
};

export { fetchCardsTransactionsService };
