// Services
import { fetchCardsTransactionsService } from '../services/transactions-landing.service';
// Types
import { ChallengeType } from 'types/challenge.type';
import { TransactionsApiReturnComponentType } from 'types/transactions-api-return.component.type';
import { TransactionsTableMetadataComponentType } from 'types/card-transactions-metadata.type';
import { FormValuesParamsType } from 'components/filters/context/types/filters-context.type';
// Utils
import { isValidTransactionsApiContract } from 'utils/transactions-api-contract.utils';

const fetchCardsTransactionsResolver = async (
  cardId: string,
  formValuesParams: FormValuesParamsType,
  challenge: ChallengeType | null,
  metadata: TransactionsTableMetadataComponentType
): Promise<[TransactionsApiReturnComponentType] | [null, number]> => {
  const [response, { status }] = await fetchCardsTransactionsService({
    cardId,
    challenge,
    formValuesParams,
    metadata,
  });
  return isValidTransactionsApiContract(response)
    ? [response as TransactionsApiReturnComponentType]
    : [null, status];
};

export { fetchCardsTransactionsResolver };
