// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useTransactionsEppContextConsumerHook } from 'containers/transactions/views/epp/contexts/transactions-epp.context';
// Types
import { TransactionsEppStep1HookType } from './types/transactions-epp-step1.hook.type';
// Utilities
import { cardActive } from 'utils/card.utils';
import { eppPlanInstallmentStep1FooterIsDisabled } from './utils/transactions-epp-step1.hook.utils';

const useTransactionsEppStep1Hook = (): TransactionsEppStep1HookType => {
  const { currentCard } = AppContextConsumerHook(),
    { eppPlanSimulation } = useTransactionsEppContextConsumerHook(),
    { status } = currentCard || {};

  return {
    cardEnabled: cardActive(status),
    disabled: eppPlanInstallmentStep1FooterIsDisabled({ eppPlanSimulation, status }),
  };
};

export { useTransactionsEppStep1Hook };
