// Vendors
import React from 'react';
// Components
import { TransactionsReportWizardComponent } from '../wizard/transactions-report-wizard.component';
// Context
import { ReportDefectiveContextConsumerHOC } from './contexts/report-defective.context';
// Configurations
import { REPORT_DEFECTIVE_STEPS_CONFIGURATION } from './configurations/report-defective.configurations';

const ReportDefectiveBaseContainer = (): React.ReactElement => (
  <TransactionsReportWizardComponent steps={REPORT_DEFECTIVE_STEPS_CONFIGURATION} />
);

const ReportDefectiveContainer = ReportDefectiveContextConsumerHOC(ReportDefectiveBaseContainer);

export { ReportDefectiveContainer };
