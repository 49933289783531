// Vendors
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Constants
import { TRANSACTION_ID_PARAM } from 'constants/routes.constants';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Utilities
import { generatePathRoute } from 'utils/app-routing.utils';

const TRANSACTIONS_LANDING_ROUTE_PROPS = {
    index: true,
  },
  TRANSACTIONS_REPORT_ROUTE_PROPS = {
    path: generatePathRoute([
      TRANSACTION_ID_PARAM,
      RoutesEnumeration.REPORT_A_PROBLEM,
      CharactersEnum.ASTERISK,
    ]),
  },
  TRANSACTIONS_PLAN_ROUTE_PROPS = {
    path: generatePathRoute([TRANSACTION_ID_PARAM, RoutesEnumeration.CREATE_INSTALLMENTS_PLAN]),
  };

export {
  TRANSACTIONS_LANDING_ROUTE_PROPS,
  TRANSACTIONS_REPORT_ROUTE_PROPS,
  TRANSACTIONS_PLAN_ROUTE_PROPS,
};
