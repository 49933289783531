// Components
import { TransactionsReportFraudulentMovementAdditionalInfoComponent } from '../components/additional-info/transactions-report-fraudulent-movement-additional-info.component';
import { TransactionsReportFraudulentMovementBlockCardComponent } from '../components/block-card/transactions-report-fraudulent-movement-block-card.component';
import { TransactionsReportFraudulentMovementConfirmationComponent } from '../components/confirmation/transactions-report-fraudulent-movement-confirmation.component';
import { TransactionsReportFraudulentMovementUploadDocumentsComponent } from '../components/upload-documents/transactions-report-fraudulent-movement-upload-documents.component';
// Translations
import {
  ADDITIONAL_INFORMATION_TITLE,
  CONFIRMATION_TITLE,
  UPLOAD_DOCUMENTS_OPTIONAL_TITLE,
} from 'containers/transactions/views/report/translations/transactions-report.translations';
import {
  ADDITIONAL_INFORMATION_DESCRIPTION,
  BLOCK_CARD_DESCRIPTION,
  BLOCK_CARD_TITLE,
} from '../translations/transactions-report-fraudulent-movement.translations';

const WIZARD_STEP_FOOTER_PROPS = {
  hasDefaultLeftButton: true,
};
const NOT_RECOGNIZE_CHARGE_ROUTE_STEPS_PROPS = [
  {
    component: TransactionsReportFraudulentMovementAdditionalInfoComponent,
    description: ADDITIONAL_INFORMATION_DESCRIPTION,
    title: ADDITIONAL_INFORMATION_TITLE,
  },
  {
    component: TransactionsReportFraudulentMovementUploadDocumentsComponent,
    title: UPLOAD_DOCUMENTS_OPTIONAL_TITLE,
  },
  {
    component: TransactionsReportFraudulentMovementConfirmationComponent,
    title: CONFIRMATION_TITLE,
  },
];

const NOT_RECOGNIZE_CHARGE_ROUTE_STEPS_WITH_BLOCK_PROPS = [
  {
    component: TransactionsReportFraudulentMovementAdditionalInfoComponent,
    description: ADDITIONAL_INFORMATION_DESCRIPTION,
    title: ADDITIONAL_INFORMATION_TITLE,
  },
  {
    component: TransactionsReportFraudulentMovementUploadDocumentsComponent,
    title: UPLOAD_DOCUMENTS_OPTIONAL_TITLE,
  },
  {
    component: TransactionsReportFraudulentMovementBlockCardComponent,
    description: BLOCK_CARD_DESCRIPTION,
    title: BLOCK_CARD_TITLE,
  },
  {
    component: TransactionsReportFraudulentMovementConfirmationComponent,
    title: CONFIRMATION_TITLE,
  },
];

export {
  NOT_RECOGNIZE_CHARGE_ROUTE_STEPS_WITH_BLOCK_PROPS,
  NOT_RECOGNIZE_CHARGE_ROUTE_STEPS_PROPS,
  WIZARD_STEP_FOOTER_PROPS,
};
