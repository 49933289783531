const DOMAIN_REPORT_FRAUDULENT_MOVEMENT_ADDITIONAL_INFO_CONFIRMATIONS =
  'cobranded.services.userSettings.privateArea.transactions.reportAProblem.fraudulentMovement.additionalInfo.';
const DOMAIN_REPORT_FRAUDULENT_MOVEMENT_ADDITIONAL_INFO_OPTIONS_STEP =
  'cobranded.services.userSettings.privateArea.transactions.reportAProblem.fraudulentMovement.firstStep.';

const EXTRA_INFORMATION = `${DOMAIN_REPORT_FRAUDULENT_MOVEMENT_ADDITIONAL_INFO_OPTIONS_STEP}extraInformation`;

const EXTRA_INFORMATION_PLACEHOLDER = `${DOMAIN_REPORT_FRAUDULENT_MOVEMENT_ADDITIONAL_INFO_OPTIONS_STEP}extraInformation.placeholder`;

const FIRST_CONFIRM_AFFIRMATIVE = `${DOMAIN_REPORT_FRAUDULENT_MOVEMENT_ADDITIONAL_INFO_CONFIRMATIONS}firstConfirm.affirmative`;

const FIRST_CONFIRM_NEGATIVE = `${DOMAIN_REPORT_FRAUDULENT_MOVEMENT_ADDITIONAL_INFO_CONFIRMATIONS}firstConfirm.negative`;

const FIRST_CONFIRM_TITLE = `${DOMAIN_REPORT_FRAUDULENT_MOVEMENT_ADDITIONAL_INFO_CONFIRMATIONS}firstConfirm.description`;

const SECOND_CONFIRM_AFFIRMATIVE = `${DOMAIN_REPORT_FRAUDULENT_MOVEMENT_ADDITIONAL_INFO_OPTIONS_STEP}secondConfirm.affirmative`;

const SECOND_CONFIRM_NEGATIVE = `${DOMAIN_REPORT_FRAUDULENT_MOVEMENT_ADDITIONAL_INFO_OPTIONS_STEP}secondConfirm.negative`;

const SECOND_CONFIRM_TITLE = `${DOMAIN_REPORT_FRAUDULENT_MOVEMENT_ADDITIONAL_INFO_OPTIONS_STEP}secondConfirm.description`;

const THIRD_CONFIRM_FIFTH_OPTION = `${DOMAIN_REPORT_FRAUDULENT_MOVEMENT_ADDITIONAL_INFO_OPTIONS_STEP}thirdConfirm.stolen`;

const THIRD_CONFIRM_FIRST_OPTION = `${DOMAIN_REPORT_FRAUDULENT_MOVEMENT_ADDITIONAL_INFO_OPTIONS_STEP}thirdConfirm.notReceived`;

const THIRD_CONFIRM_FOURTH_OPTION = `${DOMAIN_REPORT_FRAUDULENT_MOVEMENT_ADDITIONAL_INFO_OPTIONS_STEP}thirdConfirm.lost`;

const THIRD_CONFIRM_SECOND_OPTION = `${DOMAIN_REPORT_FRAUDULENT_MOVEMENT_ADDITIONAL_INFO_OPTIONS_STEP}thirdConfirm.forgotten`;

const THIRD_CONFIRM_SIXTH_OPTION = `${DOMAIN_REPORT_FRAUDULENT_MOVEMENT_ADDITIONAL_INFO_OPTIONS_STEP}thirdConfirm.paymentNotRecognized`;

const THIRD_CONFIRM_TITLE = `${DOMAIN_REPORT_FRAUDULENT_MOVEMENT_ADDITIONAL_INFO_OPTIONS_STEP}thirdConfirm.description`;

export {
  EXTRA_INFORMATION_PLACEHOLDER,
  EXTRA_INFORMATION,
  FIRST_CONFIRM_AFFIRMATIVE,
  FIRST_CONFIRM_NEGATIVE,
  FIRST_CONFIRM_TITLE,
  SECOND_CONFIRM_AFFIRMATIVE,
  SECOND_CONFIRM_NEGATIVE,
  SECOND_CONFIRM_TITLE,
  THIRD_CONFIRM_FIFTH_OPTION,
  THIRD_CONFIRM_FIRST_OPTION,
  THIRD_CONFIRM_FOURTH_OPTION,
  THIRD_CONFIRM_SECOND_OPTION,
  THIRD_CONFIRM_SIXTH_OPTION,
  THIRD_CONFIRM_TITLE,
};
