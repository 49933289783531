// Vendors
import React from 'react';
// Components
import WizardComponent from 'components/wizard/wizard.component';
import SupportCancelContractSidebarComponent from './components/sidebar/support-cancel-contract-sidebar.component';
// Constants
import { CANCEL_CONTRACT_WIZARD_PROPS } from './constants/support-cancel-contract-step.configuration.constants';
// Contexts
import { SupportCancelContractContextConsumerHOC } from './contexts/support-cancel-contract.context';
// Styles
import {
  SupportCancelContractContainerStyled,
  SupportCancelContractContentContainerStyled,
  SupportCancelContractContentLeftComponentStyled,
  SupportCancelContractGridContentRightComponentStyled,
} from './support-cancel-contract.container.styled';

const SupportCancelContractContainer = (): React.ReactElement => (
  <SupportCancelContractContainerStyled>
    <SupportCancelContractContentContainerStyled>
      <SupportCancelContractContentLeftComponentStyled>
        <WizardComponent {...CANCEL_CONTRACT_WIZARD_PROPS} />
      </SupportCancelContractContentLeftComponentStyled>
      <SupportCancelContractGridContentRightComponentStyled>
        <SupportCancelContractSidebarComponent />
      </SupportCancelContractGridContentRightComponentStyled>
    </SupportCancelContractContentContainerStyled>
  </SupportCancelContractContainerStyled>
);

export default SupportCancelContractContextConsumerHOC(SupportCancelContractContainer);
