// Vendors
import React from 'react';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
import { TransactionsLandingRowDetailsAmountComponent } from './components/amount/transactions-landing-row-details-amount.component';
import TransactionsLandingRowDetailsCategoryComponent from './components/category/transactions-landing-row-details-category.component';
import TransactionsLandingRowDetailsCreatePlanComponent from './components/create-plan/transactions-landing-row-details-create-plan.component';
import TransactionsLandingRowDetailsDateComponent from './components/date/transactions-landing-row-details-date.component';
import TransactionsLandingRowDetailsPointsComponent from './components/points/transactions-landing-row-details-points.component';
import TransactionsLandingRowDetailsReportComponent from './components/report/transactions-landing-row-details-report.component';
import TransactionsLandingRowDetailsSubcategoryComponent from './components/subcategory/transactions-landing-row-details-subcategory.component';
import TransactionsLandingRowDetailsViewInstallmentComponent from './components/view-installment/transactions-landing-row-details-view-installment.component';
import TransactionsLandingRowDetailsViewOrderComponent from './components/view-order/transactions-landing-row-details-view-order.component';
import TransactionsLandingRowDetailsReportMessageComponent from './components/report/components/transactions-landing-row-details-report-message.component';
// Hooks
import TransactionsLandingRowDetailsHook from './hooks/transactions-landing-row-details.hook';
// Resolvers
import { fetchCardsTransactionsDetailResolver } from 'containers/transactions/resolvers/transactions.resolvers';
// Styles
import { TransactionsLandingRowDetailsComponentStyled } from './transactions-landing-row-details.component.styled';
// Types
import { TransactionsLandingRowDetailsComponentType } from './types/transactions-landing-row-details.component.type';
// Utils
import { showDetailComponent, showMessage } from './utils/transactions-landing-row-details.utils';

const TransactionsLandingRowDetailsComponent = ({
  element: { id },
  open,
}: TransactionsLandingRowDetailsComponentType): React.ReactElement | any | null => {
  const { details, fetching, formatDate } = TransactionsLandingRowDetailsHook({
    id,
    open,
    resolverDetail: fetchCardsTransactionsDetailResolver,
  });

  const {
    amounts,
    category,
    date,
    eppStatusCode,
    reward,
    report,
    isAMZ,
    time,
    transactionLabel,
    detailedAmount,
  } = details;

  return open ? (
    <FetchErrorComponent {...{ fetching }}>
      {showDetailComponent(details) && (
        <TransactionsLandingRowDetailsComponentStyled>
          <TransactionsLandingRowDetailsCategoryComponent {...{ category, transactionLabel, id }} />
          <TransactionsLandingRowDetailsSubcategoryComponent
            {...{ category, id, transactionLabel }}
          />
          <TransactionsLandingRowDetailsDateComponent {...{ date, formatDate, time }} />
          {detailedAmount && (
            <TransactionsLandingRowDetailsAmountComponent {...{ detailedAmount, amounts }} />
          )}
          <TransactionsLandingRowDetailsPointsComponent {...{ reward }} />
          {showMessage(report) ? (
            <TransactionsLandingRowDetailsReportMessageComponent {...{ ...report }} />
          ) : (
            <TransactionsLandingRowDetailsReportComponent {...{ id, report }} />
          )}
          {isAMZ && <TransactionsLandingRowDetailsViewOrderComponent />}
          <TransactionsLandingRowDetailsViewInstallmentComponent {...{ eppStatusCode, id }} />
          <TransactionsLandingRowDetailsCreatePlanComponent {...{ eppStatusCode, id, reward }} />
        </TransactionsLandingRowDetailsComponentStyled>
      )}
    </FetchErrorComponent>
  ) : null;
};
export default TransactionsLandingRowDetailsComponent;
