// Vendors
import React from 'react';
import { useParams } from 'react-router-dom';
// Components
import { TransactionsReportConfirmationComponent } from 'containers/transactions/views/report/components/confirmation/transactions-report-confirmation.component';
import { TransactionsReportDifferentAmountAtmConfirmationActionsComponent } from './components/actions/transactions-report-different-amount-atm-confirmation-actions.component';
import { ReportProblemChallengeComponent } from 'containers/transactions/views/report/components/challenge/report-problem-challenge.component';
// Constants
import { DISPUTE_PARAMS } from './constants/transactions-report-different-amount-atm-confirmation.constants';
// Contexts
import { useTransactionsReportDifferentAmountAtmContextConsumerHook } from '../../context/transactions-report-different-amount-atm.context';
import { AppContextConsumerHook } from 'contexts/app.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import { TransactionsReportDifferentAmountAtmConfirmationComponentStyled } from './transactions-report-different-amount-atm-confirmation.styled';
// Translations
import { CLAIM_TYPE } from './translations/transactions-report-different-amount-atm-confirmation.translations';
// Utilities
import { determineClaimReasonType } from './utils/transactions-report-different-amount-atm-confirmation.utils';

export const TransactionsReportDifferentAmountAtmConfirmationComponent = (): React.ReactElement => {
  const { formatMessage } = useCbIntl();
  const { formValues } = useTransactionsReportDifferentAmountAtmContextConsumerHook();
  const { transactionId } = useParams();
  const { currentCard } = AppContextConsumerHook(),
    { id: cardId } = currentCard || {};

  return (
    <TransactionsReportDifferentAmountAtmConfirmationComponentStyled>
      <TransactionsReportConfirmationComponent claimType={formatMessage({ id: CLAIM_TYPE })} />
      <ReportProblemChallengeComponent
        baseServiceBuilder={{
          pathParams: {
            cardId,
            transactionId,
          },
          params: {
            ...DISPUTE_PARAMS,
            claimReasonType: determineClaimReasonType({ formValues }),
            receiveDiffAmountThanRequested: formValues,
          },
        }}
      />
      <TransactionsReportDifferentAmountAtmConfirmationActionsComponent />
    </TransactionsReportDifferentAmountAtmConfirmationComponentStyled>
  );
};
