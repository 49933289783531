// Vendors
import React from 'react';
import { Controller } from 'react-hook-form';
// Components
import { InputPrefixSuffixComponent, InputTextAreaComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  ATM_MORE_INFO_INPUT_PROPS,
  ATM_RECEIVED_CONTROLLER_RULES,
  ATM_REQUESTED_CONTROLLER_RULES,
  ATM_REQUESTED_CONTROLLER_TYPE,
  ATM_REQUESTED_INPUT_PROPS,
} from './constants/transactions-report-different-amount-atm-additional-info-inputs.constants';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import {
  TransactionsReportDifferentAmountAtmAdditionalInfoInputsComponentStyled,
  TransactionsReportDifferentAmountAtmAdditionalInfoInputsCurrencyComponentStyled,
} from './transactions-report-different-amount-atm-additional-info-inputs.component.styled';
// Translations
import {
  AMOUNT_RECEIVED,
  AMOUNT_RECEIVED_VALIDATION,
  AMOUNT_REQUESTED,
  MORE_INFO,
  MORE_INFO_PLACEHOLDER,
} from './translations/transactions-report-different-amount-atm-additional-info-inputs.translations';
// Types
import { TransactionsReportDifferentAmountAtmAdditionalInfoInputsComponentType } from './types/transactions-report-different-amount-atm-additional-info-inputs.component.type';
// Utilities
import { validateIsTheAmountReceivedLessThanRequested } from './utils/transactions-report-different-amount-atm-additional-info-inputs.utils';

export const TransactionsReportDifferentAmountAtmAdditionalInfoInputsComponent = ({
  control,
  errors,
}: TransactionsReportDifferentAmountAtmAdditionalInfoInputsComponentType): React.ReactElement => {
  const { formatMessage } = useCbIntl();

  return (
    <TransactionsReportDifferentAmountAtmAdditionalInfoInputsComponentStyled>
      <TransactionsReportDifferentAmountAtmAdditionalInfoInputsCurrencyComponentStyled>
        <Controller
          control={control}
          name={ATM_REQUESTED_CONTROLLER_TYPE.movementAtmImportRequested}
          rules={{
            ...ATM_REQUESTED_CONTROLLER_RULES,
            validate: (_, values) => validateIsTheAmountReceivedLessThanRequested(values),
          }}
          render={({ field }) => (
            <InputPrefixSuffixComponent
              {...ATM_REQUESTED_INPUT_PROPS}
              label={formatMessage({ id: AMOUNT_REQUESTED })}
              {...field}
              onChange={field.onChange}
            />
          )}
        />
      </TransactionsReportDifferentAmountAtmAdditionalInfoInputsCurrencyComponentStyled>
      <TransactionsReportDifferentAmountAtmAdditionalInfoInputsCurrencyComponentStyled>
        <Controller
          control={control}
          name={ATM_REQUESTED_CONTROLLER_TYPE.movementAtmImportReceived}
          rules={{
            ...ATM_RECEIVED_CONTROLLER_RULES,
            validate: (_, values) => validateIsTheAmountReceivedLessThanRequested(values),
          }}
          render={({ field }) => (
            <InputPrefixSuffixComponent
              {...ATM_REQUESTED_INPUT_PROPS}
              {...field}
              label={formatMessage({ id: AMOUNT_RECEIVED })}
              errorMessage={
                errors?.movementAtmImportReceived?.message &&
                formatMessage({ id: errors?.movementAtmImportReceived?.message })
              }
              onChange={field.onChange}
            />
          )}
        />
      </TransactionsReportDifferentAmountAtmAdditionalInfoInputsCurrencyComponentStyled>
      <Controller
        control={control}
        name={ATM_REQUESTED_CONTROLLER_TYPE.movementAtmOtherInfo}
        render={({ field }) => (
          <InputTextAreaComponent
            {...ATM_MORE_INFO_INPUT_PROPS}
            placeholder={formatMessage({ id: MORE_INFO_PLACEHOLDER })}
            label={formatMessage({ id: MORE_INFO })}
            {...field}
          />
        )}
      />
    </TransactionsReportDifferentAmountAtmAdditionalInfoInputsComponentStyled>
  );
};
