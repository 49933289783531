// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import constants from './constants/app.context.constants';
// Types
import { AppContextType } from './types/app.context.type';
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { CardComponentType } from 'types/card.component.type';
import { LocalizationConfigurationType } from 'types/localization-configuration.type';

const AppContext = createContext<AppContextType>(constants.CONTEXT_DEFAULT_VALUE);

export const AppContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<AppContextType>> => {
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [cards, setCards] = useState<CardComponentType[]>([]);
  const [currentCard, setCurrentCard] = useState<CardComponentType | Record<string, any>>({});
  const [featureFlags, setFeatureFlags] = useState<any[]>([]);
  const [localization, setLocalization] = useState<
    LocalizationConfigurationType | Record<string, any>
  >({});
  const [languagePreference, setLanguagePreference] = useState<string | null>(null);
  const [profile, setProfile] = useState<any>({});
  const [openRequestSignatureModal, setOpenRequestSignatureModal] = useState<boolean>(false);

  return (
    <AppContext.Provider
      value={{
        cards,
        currentCard,
        customerId,
        featureFlags,
        languagePreference,
        localization,
        openRequestSignatureModal,
        profile,
        setCards,
        setCurrentCard,
        setCustomerId,
        setFeatureFlags,
        setLanguagePreference,
        setLocalization,
        setOpenRequestSignatureModal,
        setProfile,
      }}
    >
      {children as React.ReactNode}
    </AppContext.Provider>
  );
};

export const AppContextConsumerHook = (): AppContextType => useContext(AppContext);

/* eslint-disable */
export const AppContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<AppContextType>> =>
    (
      <AppContextProvider>
        <Component {...props} />
      </AppContextProvider>
    );

/**
 * Fetching en entrypoint para bloquear pantalla para operativas
 * Fetching para bloquear hasta que cargue card ( splash )
 * Separar contexts y quitar los HOCS
 * Colocar card card-status-tag( ver donde se usar y tocar la card si necesario, refactorizar card settings)
 */
