// Vendors
import React from 'react';
import { useParams } from 'react-router-dom';
// Components
import { TransactionsReportConfirmationComponent } from 'containers/transactions/views/report/components/confirmation/transactions-report-confirmation.component';
import { ReportSubscriptionAlreadyCancelledConfirmationActionsComponent } from './components/actions/report-subscription-already-cancelled-confirmation-actions.component';
import { ReportProblemChallengeComponent } from 'containers/transactions/views/report/components/challenge/report-problem-challenge.component';
// Constants
import { DISPUTE_PARAMS } from './constants/report-subscription-already-cancelled-confirmation.constants';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useReportSubscriptionAlreadyCancelledContextConsumerHook } from '../../contexts/report-subscription-already-cancelled.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import { ReportSubscriptionAlreadyCancelledConfirmationComponentStyled } from './report-subscription-already-cancelled-confirmation.component.styled';
// Translations
import { CLAIM_TYPE } from './translations/report-subscription-already-cancelled-confirmation.translations';
// Utils
import { getDocumentIds } from 'containers/transactions/views/report/utils/transactions-report.utils';

export const ReportSubscriptionAlreadyCancelledConfirmationComponent = (): React.ReactElement => {
  const { formatMessage } = useCbIntl();
  const { cancelledSubDateBefore, files } =
    useReportSubscriptionAlreadyCancelledContextConsumerHook();
  const { transactionId } = useParams();
  const { currentCard } = AppContextConsumerHook(),
    { id: cardId } = currentCard || {};

  return (
    <ReportSubscriptionAlreadyCancelledConfirmationComponentStyled>
      <TransactionsReportConfirmationComponent claimType={formatMessage({ id: CLAIM_TYPE })} />
      <ReportProblemChallengeComponent
        baseServiceBuilder={{
          pathParams: {
            cardId,
            transactionId,
          },
          params: {
            ...DISPUTE_PARAMS,
            subscriptionCanceled: {
              cancelledSubDateBefore,
            },
            documents: getDocumentIds(files),
          },
        }}
      />
      <ReportSubscriptionAlreadyCancelledConfirmationActionsComponent />
    </ReportSubscriptionAlreadyCancelledConfirmationComponentStyled>
  );
};
