// Vendors
import React from 'react';
// Components
import { RadioButtonComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  OPTIONS_STRINGS,
  DATA_TEST_ID,
} from './constants/report-defective-additional-info.constants';
// Styles
import {
  ReportDefectiveAdditionalInformationContainerStyled,
  ReportDefectiveAdditionalInformationOptionsStyled,
} from './report-defective-additional-info.component.styled';
// Translations
import {
  RADIO_BUTTON_NO,
  RADIO_BUTTON_YES,
  TITLE_OPTIONS,
} from './translations/report-defective-additional-info.translations';
// Hooks
import { ReportDefectiveAdditionalInfoHook } from './hooks/report-defective-additional-info.hook';

export const ReportDefectiveAdditionalInfoComponent = (): React.ReactElement => {
  const {
    handleSelectedOption,
    selectedOption,
    defectProductReturnProductCommerce,
    existsReturnProductCommerce,
  } = ReportDefectiveAdditionalInfoHook();

  return (
    <ReportDefectiveAdditionalInformationContainerStyled {...DATA_TEST_ID.wrapper}>
      <ReportDefectiveAdditionalInformationOptionsStyled {...DATA_TEST_ID.options}>
        <FormattedMessageComponent id={TITLE_OPTIONS} />
        <RadioButtonComponent
          onChange={() => handleSelectedOption(OPTIONS_STRINGS.YES_OPTION)}
          value={
            selectedOption === OPTIONS_STRINGS.YES_OPTION ||
            (existsReturnProductCommerce() && defectProductReturnProductCommerce)
          }
        >
          <ParagraphComponent>
            <FormattedMessageComponent id={RADIO_BUTTON_YES} />
          </ParagraphComponent>
        </RadioButtonComponent>
        <RadioButtonComponent
          onChange={() => handleSelectedOption(OPTIONS_STRINGS.NO_OPTION)}
          value={
            selectedOption === OPTIONS_STRINGS.NO_OPTION ||
            (existsReturnProductCommerce() && !defectProductReturnProductCommerce)
          }
        >
          <ParagraphComponent>
            <FormattedMessageComponent id={RADIO_BUTTON_NO} />
          </ParagraphComponent>
        </RadioButtonComponent>
      </ReportDefectiveAdditionalInformationOptionsStyled>
      <WizardStepFooterComponent
        rightButtonConfiguration={{ disabled: !existsReturnProductCommerce() }}
      />
    </ReportDefectiveAdditionalInformationContainerStyled>
  );
};
