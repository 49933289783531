// Enumerations
import { TransactionsStatusCodeEnumeration } from 'enumerations/transactions-status-code.enumeration';

const transactionHasBeenNotAuthorizedAndLiquidated = (transactionStatusCode: any): boolean =>
  ![
    TransactionsStatusCodeEnumeration.AUTHORIZED,
    TransactionsStatusCodeEnumeration.LIQUIDATED,
  ].includes(transactionStatusCode);

export { transactionHasBeenNotAuthorizedAndLiquidated };
