// Constants
import { TRANSACTION_LANDING_ROW_DETAILS_DATE_FORMAT_PROPS } from './constants/transactions-landing-row-details-date.constants.util';
// Types
import { TransactionsLandingRowDetailsGetDetailsDateTimeType } from '../types/transactions-landing-row.details-date.component.utils.type';

const getDetailsDateTime = ({
  date,
  formatDate,
  options,
  time,
}: TransactionsLandingRowDetailsGetDetailsDateTimeType): string =>
  `${formatDate(`${date} ${time}`, options)}`.replace(
    TRANSACTION_LANDING_ROW_DETAILS_DATE_FORMAT_PROPS.SUFFIX,
    TRANSACTION_LANDING_ROW_DETAILS_DATE_FORMAT_PROPS.SEPARATOR
  );

export { getDetailsDateTime };
