// Translations
import {
  ERROR_MAX_RANGE_SLIDER,
  ERROR_MIN_RANGE_SLIDER,
} from './translations/transactions-epp-step1-simulation-options-all-slider.utils.translations';
// Types
import { TransactionsEppStep1SimulationOptionsAllSliderUtilsType } from './types/transactions-epp-step1-simulation-options-all-slider.utils.type';

const allOptionsIsHigherThanMax = ({
  defaultValue,
  max,
}: Pick<
  TransactionsEppStep1SimulationOptionsAllSliderUtilsType,
  'defaultValue' | 'max'
>): boolean => Number(defaultValue) > max;

const allOptionsValueIsOutAllowedRange = ({
  defaultValue,
  min,
  max,
}: Omit<TransactionsEppStep1SimulationOptionsAllSliderUtilsType, 'formatMessage'>): boolean =>
  Boolean(Number(defaultValue) < min || allOptionsIsHigherThanMax({ defaultValue, max }));

const getSimulationOptionsRangeError = ({
  defaultValue,
  formatMessage,
  max,
  min,
}: TransactionsEppStep1SimulationOptionsAllSliderUtilsType): string =>
  allOptionsIsHigherThanMax({ defaultValue, max })
    ? formatMessage({ id: ERROR_MAX_RANGE_SLIDER }, { max })
    : formatMessage({ id: ERROR_MIN_RANGE_SLIDER }, { min });

const getSimulationAllOptionsErrorMessage = ({
  formatMessage,
  ...props
}: TransactionsEppStep1SimulationOptionsAllSliderUtilsType): string | null =>
  allOptionsValueIsOutAllowedRange(props)
    ? getSimulationOptionsRangeError({ formatMessage, ...props })
    : null;

export { allOptionsValueIsOutAllowedRange, getSimulationAllOptionsErrorMessage };
