const ATM_REQUESTED_CONTROLLER_TYPE = {
  movementAtmImportRequested: 'movementAtmImportRequested',
  movementAtmImportReceived: 'movementAtmImportReceived',
  movementAtmOtherInfo: 'movementAtmOtherInfo',
};

const ATM_REQUESTED_CONTROLLER_RULES = {
  required: true,
  deps: [ATM_REQUESTED_CONTROLLER_TYPE.movementAtmImportReceived],
};

const ATM_RECEIVED_CONTROLLER_RULES = {
  required: true,
};

const ATM_REQUESTED_INPUT_PROPS = {
  placeholder: '0,00',
  maxOfDecimalsInCurrency: 0,
  type: 'currency',
  suffix: '€',
};

const ATM_MORE_INFO_INPUT_PROPS = {
  limitCharsConfiguration: { limit: 600 },
  resize: true,
};

export {
  ATM_MORE_INFO_INPUT_PROPS,
  ATM_RECEIVED_CONTROLLER_RULES,
  ATM_REQUESTED_CONTROLLER_RULES,
  ATM_REQUESTED_CONTROLLER_TYPE,
  ATM_REQUESTED_INPUT_PROPS,
};
